import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import localForage from "localforage";


@Injectable()
export class AuthService {

  constructor(@Inject("env") private env) {}
  
  getDepartmentList() {
    return new Promise((resolve, reject) => {
      resolve(
        localForage.getItem("auth-data").then( (data: any) => {
          return data.userDepartmentList;
        })
      );
    });
  }
  
  getData() {
    return localForage.getItem("auth-data");
  }

  getUserInfo() {
    let userSpecialRightsList = localStorage.getItem("userRightSpecialList");
    return localForage.getItem("auth-data").then((data: any) => {
      return {
        userTypeName: (data.employeeType && data.employeeType.employeeTypeDescription)? data.employeeType.employeeTypeDescription : null,
        userName: data.userName,
        name: data.userName,
        email:data.userEmail,
        userId: data.userId,
        autho:data.authorization,
        userSpecialRightList:userSpecialRightsList?JSON.parse(userSpecialRightsList):[],
        user_code: null,
        userTypeImage:data.userType?data.userType.userTypeCode:"",
        userRefernceId:data.employeeDetails?data.employeeDetails.employeeId:0
      };
    });
  }

  getGeneralSettingInfo() {
    return localForage.getItem("auth-data").then((data: any) => {
      return {
        slotTime: data.generalSetting?data.generalSetting.slotTime:"",
      };
    });
  }

  getUser() {
    return localForage.getItem("auth-data");
  }

  getUnit() {
    return localForage.getItem("unit");
  }

  getNotification() {
    return localForage.getItem("notification");
  }

  getRole() {
    return localForage.getItem("role");
  }
  getSchedule() {
    return localForage.getItem("schedule");
  }

  //get counter related information
  getCounterName() {
    return localForage.getItem("counterName");
  }
  getCounterCode() {
    return localForage.getItem("counterCode");
  }
  getShiftId() {
    return localForage.getItem("shiftId");
  }
  getShiftNo() {
    return localForage.getItem("shiftNo");
  }

  getUnitList() {
    return new Promise((resolve, reject) => {
      resolve(
        localForage.getItem("auth-data").then((data: any) =>{
          return data.userUnitList;
        })
      );
    });
  }

  getSubDepartList() {
    return new Promise((resolve, reject) => {
      resolve(
        localForage.getItem("auth-data").then((data: any) => {
          return data.userSubDepartmentList;
        })
      );
    });
  }

  getStore() {
    return new Promise((resolve, reject) => {
      try {
        const data = localStorage.getItem("userStoreList");
        const stationId = atob(localStorage.getItem("storeId"));
        if (data) {
          const parsedData = JSON.parse(data);
          const store = parsedData.find((i) => i.storeId === stationId) || null;
          resolve(store);
        } else {
          resolve(null); 
        }
      } catch (error) {
        console.error("Error retrieving or parsing userStoreList:", error);
        reject(error); 
      }
    });
  }
  

  getStoreList() {
    return new Promise((resolve, reject) => {
      try {
        const data = localStorage.getItem("userStoreList");
        const parsedData = data ? JSON.parse(data) : [];
        resolve(parsedData);
      } catch (error) {
        console.error("Error retrieving or parsing userStoreList:", error);
        reject(error);
      }
    });
  }

  getRoleList() {
    return new Promise((resolve, reject) => {
      resolve(
        localForage.getItem("auth-data").then((data: any) => {
          return data.userUnitRoleList;
        })
      );
    });
  }

  getScheduleList() {
    let _this = this;
    return new Observable<boolean>(observer => {
      _this.getSchedule().then((data: any) => {
        return data;
      });
    });
  }

  getCounterList() {
    return new Promise((resolve, reject) => {
      try {
        const data = localStorage.getItem("userRightSpecialList");
        const parsedData = data ? JSON.parse(data) : [];
        resolve(parsedData);
      } catch (error) {
        console.error("Error retrieving or parsing userRightSpecialList:", error);
        reject(error);
      }
    });
  }

  getCashCounterList() {
    return new Promise((resolve, reject) => {
      try {
        const data = localStorage.getItem("userDetailWithCashCounters");
        const parsedData = data ? JSON.parse(data) : [];
        resolve(parsedData);
      } catch (error) {
        console.error("Error retrieving or parsing cashCounterList:", error);
        reject(error);
      }
    });
  }

}
