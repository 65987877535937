
  <!-- Copyright &copy; {{ footerDate | date: "yyyy" }}
  <a href="https://www.sdglobaltech.com/" target="_blank"
    >www.sdglobaltech.com</a
  >
  ( {{ medcareVersion | translate }} ) | All Rights Reserved -->
    
  <!-- <nav class="footer" >
  </nav> -->
  <div class="footer-content" *ngIf="showFooter">
    <div class="refresh-button">
      <mat-icon style="color:#339480; cursor: pointer;" matTooltip="Refresh" (click)="onRefreshClick($event)">
      refresh
      </mat-icon>
    </div>
    <div>
      <mat-paginator
      [length]="totalDataLength"
      [pageIndex]="pageIndex"
      [pageSize]="pageSize"
      [pageSizeOptions]="paginatorOptions"
      (page)="getNext($event)">
      </mat-paginator>
    </div>
    </div>