export const MICROSERVICES = {
  BILLING_SERVICE: "/sdgt-billing-service/",
  MASTER_SERVICE: "/masters-service/",
  STOCK_SERVICE: "/stock-service/",
  PATIENT_INTEGRATION_SERVICE: "/patientintegration-service/",
  ANCILLARY_SERVICE: "/ancillary-service/",
  OPD_SERVICE: "/opd-service/",
  APP_CONFIG_SERVICE: "/appconfig-service/",
};

export const MICROSERVICESURL = {
  CASH_COUNTER: "counter/getCounterByUnit",
  SERVICE_LIST: "service/getServiceByUnit",
  GET_QM_LIST: "qm/list",
  GET_HISTORY_BY_ID: "qm/getPatientHistoryDetailById/",
  GET_PATIENT_DETAIL_BY_ID: "qm/getDetailWithPatientInfoById/",
  GET_LABEL_LIST: "qmLabel/list?limit=10&offset=0",
  PATIENT_SEARCH: "patientSearch/getPatient",
  ADD_VISITOR: "qm/save",
  UPDATE_VISIT: "qm/update",
  GET_COUNTER_BY_UNIT:"qm/getListByCounter",
  GET_APPOINTMENT:"appointment/get",
  CREATE_ENCOUNTER:"extendedEncounter/createEncounterFromQMS",
  GET_SERVICES: "service/getServiceByUnit",
  GET_DEPARTMENTS: "department/getDeptByUnit",
  GET_PATIENT: "patientSearch/getPatient",
  GET_EMPLOYEE_LIST:"employee/employeeList",
  GET_SLOT:"slot/1/Appointment/List?entities=",
  CREATE_APPOINTMENT:"appointment/new",
  M_IDENTIFICATION_TYPE:"Masters/IdentificationTypeMaster",
  M_GENDER:"Masters/GenderMaster",
  M_COUNTRY_CODE:"Masters/CountryCodeMaster",
  APP_CONFIG:"appConfiguration/findConfigByModule?unitCode=",
  CRATE_TEMP_PATIENT:"temporaryPatient/createTempPatientForQMS",
  GET_PATIENT_BY_TOKEN_NO: "qm/qmsPatientSearch"
};
