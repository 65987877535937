export const MICROSERVICES = {
  ACCOUNT_PAYABLE: "/account-payable/",
  ACCOUNTING_MASTER_SERVICE: "/accountingmaster/",
  AUDIT_SERVICE: "/audit-service/",
  BIOMEDICWASTE_SERVICE: "/biomedicwaste-service/",
  CSSD_SERVICE: "/cssd-service/",
  GENERAL_LEDGER_SERVICE: "/general-ledger/",
  GRAPHQL_SERVICE: "/graphql-service/",
  ID_SERVICE: "/id-service/",
  IDENTITY_SERVICE: "/identity-service/",
  INVENTORY_SERVICE: "/inventory-service/",
  IPD_SERVICE: "/ipd-service/",
  LAB_SERVICE: "/lab-service/",
  MASTERS_SERVICE: "/masters-service/",
  MASTERSV1_SERVICE: "/mastersv1-service/",
  MRD_SERVICE: "/mrd-service/",
  NOTIFICATION_SERVICE: "/notification-service/",
  NURSING_SERVICE: "/nursing-service/",
  OPD_SERVICE: "/opd-service/",
  FILE_SERVICE:"/file-service/",
  OTIMS_SERVICE: "/otims-service/",
  PHARMACY_SERVICE: "/pharmacy-service/",
  REPORT_SERVICE: "/report-service/",
  RULE_ENGINE_SERVICE: "/rule-engine-service/",
  SCHEDULER_SERVICE: "/scheduler-service/",
  BILLING_SERVICE: "/sdgt-billing-service/",
  APP_CONFIG_SERVICE: "/appconfig-service/",
  PROCUREMENT_SERVICE: "/procurement-service/",
  UI_SERVICE: "/master-service/",
  STOCK_SERVICE: "/stock-service/",
  ANCILLARY_SERVICE: "/ancillary-service/",
  DOC_SERVICE:"/doc-service/",
  PATIENTINTEGRATION_SERVICE:"/patientintegration-service/" ,
  DATA_MIGRATION_SERVICE:"/data-migration-service/",
  DIAGNOSIS_CODING: "mrd/diagnosisCoding/getIcdRequestDetailByVisitId?visitNo=",

};
