import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import {
  PatientRegistration,
  PatientEncounter,
  PatientNextOfKin,
  PatientEncounterPayer,
} from "medcare-core-ui";
import { MultiLanguageService } from "projects/medcare-core-ui/src/app/core/multi-language/multi-language.service";
import { ExtendedAddress } from "projects/medcare-core-ui/src/app/core/patient-search/patient-search.component";

import { ContractModel } from "../regmodels/McontractModel"; 
import { OPD_CONST } from "../regproperties/opd-static-properties";  
import {
  deceased,
  employeeDetails,
  mobileNumber,
  motherReference,
  patientSetModel,
} from "../regmodels/MpatientRegistration";
import moment from "moment";
import { STATICCODESERVICE } from "../constants/static-code-service";

@Injectable({
  providedIn: "root",
})
export class PatientRegistrationService {
  pdpaForm: boolean;
  constructor(private langService: MultiLanguageService) {}
  pateintDataSet: patientSetModel = new patientSetModel();
  getRegistrationFormValues(_this, form: FormGroup): PatientRegistration {
    const patient: PatientRegistration = new PatientRegistration();
    patient.id = form.controls.id.value;
    patient.active=form.controls.active.value;
    patient.mrn = form.controls.mrn.value;
    patient.identificationNumberPrimary = form.controls.primaryNumber.value;
    patient.tinNo = form.controls.tinNo.value ? form.controls.tinNo.value :"";
    patient.identificationNumberSecondary = form.controls.secondaryNumber
      ? form.controls.secondaryNumber.value
      : "";
    patient.expiryDate = form.controls.expiryDate
      ? form.controls.expiryDate.value
      : "";
    patient.seondaryExpiryDate = form.controls.seondaryExpiryDate
      ? form.controls.expiryDate.value
      : "";
    patient.fullName = form.controls.firstName.value.toUpperCase();
    patient.otherName = form.controls.otherName
      ? form.controls.otherName.value
      : "";
    patient.dateOfBirth = form.controls.dateOfBirth.value;
    patient.age = "";
    patient.addressPrimary = form.controls.addressPrimary
      ? form.controls.addressPrimary.value
      : "";
    patient.addressSecondary = form.controls.addressSecondary
      ? form.controls.addressSecondary.value
      : "";
    patient.postcodePrimary = form.controls.pincodePrimary
      ? form.controls.pincodePrimary.value
      : "";
    patient.postcodeSecondary = form.controls.pincodeSecondary
      ? form.controls.pincodeSecondary.value
      : "";
    patient.email = form.controls.emailId ? form.controls.emailId.value : "";

    patient.mobile = form.controls.mobileNumber
      ? form.controls.mobileNumber.value
      : "";
    patient.homeContact = form.controls.homeContact
      ? form.controls.homeContact.value
      : "";
    patient.workContact = form.controls.workContact
      ? form.controls.workContact.value
      : "";
    patient.motherName = form.controls.motherName
      ? form.controls.motherName.value
      : "";
    patient.workFax = form.controls.workFax ? form.controls.workFax.value : "";
    patient.personalFax = form.controls.personalFax
      ? form.controls.personalFax.value
      : "";
    patient.isVip = form.controls.isVip ? form.controls.isVip.value : false;
    patient.isDeceased = form.controls.isDeceased
      ? form.controls.isDeceased.value
      : false;
    patient.realdob = form.controls.realdob
      ? form.controls.realdob.value
      : false;

    patient.identificationTypePrimary = {
      code: form.controls.primaryId.value,
      desc: this.getObjByCode(
        _this.primaryIdFilteredList,
        form.controls.primaryId.value
      ),
    };

    patient.issuedCountryPrimary = {
      code: form.controls.issuedCountryPrimary.value,
      desc: this.getDescByCode(
        _this.issuedCountryPrimaryList,
        form.controls.issuedCountryPrimary.value
      ),
    };

    patient.identificationTypeSecondary = {
      code: form.controls.secondaryId.value,
      desc: this.getObjByCode(
        _this.secondaryIdList,
        form.controls.secondaryId.value
      ),
    };

    patient.issuedCountryPrimary = {
      code: form.controls.issuedCountryPrimary.value,
      desc: this.getDescByCode(
        _this.issuedCountrySecondaryList,
        form.controls.issuedCountryPrimary.value
      ),
    };

    patient.faulty = {
      code: form.controls.faculty.value,
      desc: this.getDescByCode(_this.facultyList, form.controls.faculty.value),
    };

    patient.prefixTitle = {
      code: form.controls.prefix.value,
      desc: this.getDescByCode(_this.prefixList, form.controls.prefix.value),
    };

    patient.gender = {
      code: form.controls.gender.value,
      desc: this.getDescByCode(_this.genderList, form.controls.gender.value),
    };

    patient.preferredLanguage = {
      code: form.controls.preferredLanguage.value,
      desc: this.getDescByCode(
        _this.languageList,
        form.controls.preferredLanguage.value
      ),
    };

    patient.nationality = {
      code: form.controls.nation.value,
      desc: this.getDescByCode(
        _this.nationalityList,
        form.controls.nation.value
      ),
    };

    patient.race = {
      code: form.controls.race.value?_this.nationalityWiseRace.filter(s=>s.code==form.controls.race.value):"",
      desc: this.getDescByCode(_this.nationalityWiseRace, form.controls.race.value),
    };

    patient.employmentStatus = {
      code: form.controls.employmentStatus
        ? form.controls.employmentStatus.value
        : "",
      desc: form.controls.employmentStatus
        ? this.getDescByCode(
            _this.employeeStatusList,
            form.controls.employmentStatus.value
          )
        : "",
    };

    patient.bloodGroup = {
      code: form.controls.bloodGroup.value,
      desc: this.getDescByCode(
        _this.bloodGroupList,
        form.controls.bloodGroup.value
      ),
    };

    patient.martialStatus = {
      code: form.controls.martial.value,
      desc: this.getDescByCode(
        _this.martialStatusList,
        form.controls.martial.value
      ),
    };

    patient.occupation = {
      code: form.controls.occupation.value,
      desc: this.getDescByCode(
        _this.occupationList,
        form.controls.occupation.value
      ),
    };

    patient.patientCategory = {
      code: form.controls.patientCategory.value,
      desc: this.getDescByCode(
        _this.patientCategoryList,
        form.controls.patientCategory.value
      ),
    };

    patient.religion = {
      code: form.controls.religion.value,
      desc: this.getDescByCode(
        _this.religionList,
        form.controls.religion.value
      ),
    };

    patient.countryPrimary = {
      code: form.controls.countryPrimary.value,
      desc: this.getDescByCode(
        _this.countryPrimaryList,
        form.controls.countryPrimary.value
      ),
    };

    patient.statePrimary = {
      code: form.controls.statePrimary.value,
      desc: this.getDescByCode(
        _this.statePrimaryList,
        form.controls.statePrimary.value
      ),
    };

    patient.cityPrimary = {
      code: form.controls.cityPrimary.value,
      desc: this.getDescByCode(
        _this.cityPrimaryList,
        form.controls.cityPrimary.value
      ),
    };

    patient.subDistrictPrimary = {
      code: form.controls.subDistrictPrimary.value,
      desc: this.getDescByCode(
        _this.subDistrictPrimaryList,
        form.controls.subDistrictPrimary.value
      ),
    };

    patient.countrySecondary = {
      code: form.controls.countrySecondary.value,
      desc: this.getDescByCode(
        _this.countrySecondaryList,
        form.controls.countrySecondary.value
      ),
    };

    patient.stateSecondary = {
      code: form.controls.stateSecondary.value,
      desc: this.getDescByCode(
        _this.stateSecondaryList,
        form.controls.stateSecondary.value
      ),
    };

    patient.citySecondary = {
      code: form.controls.citySecondary.value,
      desc: this.getDescByCode(
        _this.citySecondaryList,
        form.controls.citySecondary.value
      ),
    };
    patient.subDistrictSecondary = {
      code: form.controls.subDistrictSecondary.value,
      desc: this.getDescByCode(
        _this.subDistrictSecondaryList,
        form.controls.subDistrictSecondary.value
      ),
    };

    patient.relation = {
      code: form.controls.contactRelation.value,
      desc: this.getDescByCode(
        _this.relationList,
        form.controls.contactRelation.value
      ),
    };
    patient.countryCodemobile = {
      code: form.controls.countryCodemobile.value,
      desc: "",
    };

    patient.birthplace = form.controls.birthplace.value;

    patient.education = {
      code: form.controls.education.value,
      desc: this.getDescByCode(
        _this.educationList,
        form.controls.education.value
      ),
    };
    patient.ethnic = {
      code: form.controls.ethnic.value,
      desc: _this.ethnicList,
    };
    patient.sourceType = {
      code: form.controls.sourceType.value,
      desc: "",
    };
    patient.patientNotes = form.controls.patientNotes.value;
    patient.isCtos = form.controls.isCtos ? form.controls.isCtos.value : false;
    patient.isBlock = form.controls.isBlock
      ? form.controls.isBlock.value
      : false;
    patient.isNewborn = form.controls.isNewborn
      ? form.controls.isNewborn.value
      : false;
    patient.birthTime = form.controls.birthTime
      ? form.controls.birthTime.value
      : "";
    if (form.controls.employmentStatus) {
      patient.employmentStatus = {
        code: form.controls.employmentStatus.value,
        desc: this.getDescByCode(
          _this.employmentStatusList,
          form.controls.employmentStatus.value
        ),
      };

      let employer = null;
      if (form.controls.employerName.value)
        employer = _this.employerList.find(
          (i) => i.code == form.controls.employerName.value
        );
      patient.employerName = {
        code: employer,
        desc: this.getDescByCode(
          _this.employerList,
          form.controls.employerName.value
        ),
      };
      patient.employerNameOthers = form.controls.employerNameOthers
        ? form.controls.employerNameOthers.value
        : "";
      patient.employmentNo = form.controls.employmentNo
        ? form.controls.employmentNo.value
        : "";
      patient.workingFrom = form.controls.workingFrom.value;
      patient.employmentContactNo = form.controls.employmentContactNo
        ? form.controls.employmentContactNo.value
        : "";
      patient.employmentAddress = form.controls.employmentAddress
        ? form.controls.employmentAddress.value
        : "";
      patient.employmentPostCode = form.controls.employmentPostCode
        ? form.controls.employmentPostCode.value
        : "";
      patient.employmentCountry = {
        code: form.controls.employmentCountry.value,
        desc: this.getDescByCode(
          _this.countryList,
          form.controls.employmentCountry.value
        ),
      };
      patient.employmentState = {
        code: form.controls.employmentState.value,
        desc: this.getDescByCode(
          _this.stateList,
          form.controls.employmentState.value
        ),
      };
      patient.employmentCity = {
        code: form.controls.employmentCity.value,
        desc: this.getDescByCode(
          _this.cityList,
          form.controls.employmentCity.value
        ),
      };
    }

    return patient;
  }

  getEncounterFormValues(_this, form: FormGroup): PatientEncounter {
    const encounter = new PatientEncounter();

    encounter.encounterType = {
      code: form.controls.encounterType.value,
      desc: form.controls.encounterType.value
        ? form.controls.encounterType.value.desc
        : "",
    };
    encounter.department = {
      code: form.controls.department.value,
      desc: form.controls.department.value
        ? form.controls.department.value.desc
        : "",
    };
    encounter.doctor = {
      code: form.controls.doctor.value,
      desc: this.getObjByCode(
        _this.multiLangDoctorList,
        form.controls.doctor.value
      ),
    };
    if (form.controls.reportTypeMrd) {
      encounter.reportType = {
        code: form.controls.reportTypeMrd.value
          ? form.controls.reportTypeMrd.value
          : null,
        desc: form.controls.reportTypeMrd
          ? form.controls.reportTypeMrd.value
            ? form.controls.reportTypeMrd.value.desc
            : ""
          : null,
      };
      encounter.reportReason = {
        code: form.controls.reportResonMrd
          ? form.controls.reportResonMrd.value
          : null,
        desc: form.controls.reportResonMrd
          ? this.getObjByCode(
              _this.reportReasonFilterdList,
              form.controls.reportResonMrd.value
            )
          : null,
      };
      encounter.reportRemarksMrd = form.controls.reportRemarkMrd.value;
    }
    encounter.clinic = {
      code: form.controls.clinic.value,
      desc: form.controls.clinic.value ? form.controls.clinic.value.desc : "",
    };
    encounter.cashCounter = {
      code: form.controls.cashCounter.value,
      desc: form.controls.cashCounter.value
        ? form.controls.cashCounter.value.desc
        : "",
    };
    encounter.remark = form.controls.remarks.value;
    encounter.visittype = {
      code: form.controls.visittype.value,
      desc: form.controls.visittype.value
        ? form.controls.visittype.value.desc
        : "",
    };

    encounter.referralType = {
      code: form.controls.referralType.value,
      desc: form.controls.referralType.value
        ? form.controls.referralType.value.desc
        : "",
    };
    encounter.referral = {
      code: form.controls.referral.value,
      desc: form.controls.referral.value
        ? form.controls.referral.value.desc
        : "",
    };
    encounter.patientCategory = {
      code: form.controls.patientCategory.value,
      desc: form.controls.patientCategory.value
        ? form.controls.patientCategory.value.desc
        : "",
    };
    encounter.practiceNotes = form.controls.practiceNotes.value;
    encounter.reportRemarks = form.controls.reportRemarks.value;
    encounter.referralRemark = form.controls.referralRemarks.value;

    return encounter;
  }

  getNextOfKinFormValues(_this, form: FormGroup): PatientNextOfKin {
    const nextOfKin = new PatientNextOfKin();
    nextOfKin.prefix = {
      code: form.controls.prefix.value,
      desc: this.getDescByCode(_this.prefixList, form.controls.prefix.value),
    };
    nextOfKin.identificationType = {
      code: form.controls.identificationType.value,
      desc: this.getDescByCode(
        _this.relationList,
        form.controls.identificationType.value
      ),
    };
    nextOfKin.relation = {
      code: form.controls.relation.value,
      desc: this.getDescByCode(
        _this.relationList,
        form.controls.relation.value
      ),
    };
    nextOfKin.name = form.controls.name.value;
    nextOfKin.idNumber = form.controls.identificationNumber.value;
    nextOfKin.phoneNumber = form.controls.phoneNumber.value;
    nextOfKin.isGuarantor = form.controls.isGuarantor.value;
    nextOfKin.address = form.controls.address.value;
    nextOfKin.postcode = form.controls.postcode.value;
    nextOfKin.country = {
      code: form.controls.country.value,
      desc: this.getDescByCode(_this.countryList, form.controls.country.value),
    };
    nextOfKin.state = {
      code: form.controls.state.value,
      desc: this.getDescByCode(
        _this.nokStateFilteredList,
        form.controls.state.value
      ),
    };
    nextOfKin.city = {
      code: form.controls.city.value,
      desc: this.getDescByCode(
        _this.nokCityFilteredList,
        form.controls.city.value
      ),
    };
    return nextOfKin;
  }
  
  
  getNextOfKinValues(_this, value): PatientNextOfKin {
    const nextOfKin = new PatientNextOfKin();
    nextOfKin.prefix = {
      code: this.getObjByCode(_this.newPrefixList,value.prefix? value.prefix.code:""),
      desc: value.prefix?value.prefix.desc:"",
    };
    nextOfKin.identificationType = {
      code: this.getObjByCode(
        _this.secondaryIdList,
        value.identifierType? value.identifierType.desc:""
      ),
      desc:value.identifierType?value.identifierType.desc:"",
    };
    nextOfKin.relation = {
      code:this.getObjByCode(
        _this.relationList,
        value.relation?value.relation.code:""
      ),
      desc:value.relation?value.relation.desc:"",
    };
    nextOfKin.id=value.id?value.id:"";
    nextOfKin.name = value.name;
    nextOfKin.idNumber = value.identificationNo?value.identificationNo:"";
    nextOfKin.phoneNumber = value.mobileNo?value.mobileNo:"";
    nextOfKin.isGuarantor = value.isGurantor?value.isGurantor:"";
    nextOfKin.address = value.address?value.address:"";
    nextOfKin.postcode = value.postcode?value.postcode:"";
    nextOfKin.country = {
      code: this.getObjByCode(_this.countryList,value.country?value.country.code:""),
      desc: value.country?value.country.desc:"",
    };
    nextOfKin.state = {
      code:  this.getObjByCode(
        _this.nokStateFilteredList,
        value.state?value.state.code:""
      ),
      desc: value.state?value.state.desc:""
    };
    nextOfKin.city = {
      code: this.getObjByCode(
        _this.nokCityFilteredList,
        value.city?value.city.code:""
      ),
      desc: value.city?value.city.desc:"",
    };
    return nextOfKin;
  }
  getEncounterPayerFormValues(
    _this,
    table: MatTableDataSource<PatientEncounterPayer>
  ): PatientEncounterPayer[] {
    const list = [];
    table.data.forEach((element: PatientEncounterPayer) => {
      element.payerDesc = this.getObjByCode(_this.payerList, element.payerCode);
      element.payerTypeCode = element.contractDesc = this.getObjByCode(
        _this.payerTypeList,
        element.payerTypeCode
      );
      element.contractDesc = this.getObjByCode(
        _this.contractList,
        element.contractCode
      );
      element.gradeDesc = this.getObjByCode(_this.gradeList, element.gradeCode);
      element.tariffDesc = this.getObjByCode(
        _this.tariffList,
        element.tariffCode
      );
      element.associatedCompanyDesc = this.getObjByCode(
        _this.associatedCompanyList,
        element.associatedCompanyCode
      );
      element.glTypeDesc = this.getObjByCode(
        _this.glTypeList,
        element.glTypeCode
      );
      element.effectiveDate =  element.effectiveDate ? new Date(element.effectiveDate) : null;
      element.expiryDate = element.expiryDate ? new Date(element.expiryDate) : null;
      element.policyNumber = element.policyNumber;
      list.push(element);
    });

    return list;
  }

  getDescByCode(list: any[], code: string): string {
    let desc = "";
    if (list && list.length > 0) {
      const value = list.find((i) => i.code == code);
      if (value && value.desc) desc = value.desc;
    }
    return desc;
  }

  getCodeByDesc(list: any[], desc: string): string {
    let code = "";
    if (list && list.length > 0) {
      const value = list.find((i) => i.desc.toLowerCase() == desc.toLowerCase());
      if (value && value.code) code = value.code;
    }
    return code;
  }
  getObjByCode(list: any[], code: string): string {
    let value;
    if (list && list.length > 0) {
      value = list.find((i) => i.code == code);
    }
    return value;
  }
  getCodeBySystemCode(list: any[], systemCode: string): string {
    let code = "";
    if (list && list.length > 0 && systemCode) {
      let value = list.find((i) => i.systemCode.toLowerCase() == systemCode.toLowerCase());
      const othersValue: string = STATICCODESERVICE.OTHERS;
      if(!value) value = list.find((i) => i.systemCode.toLowerCase() == othersValue.toLowerCase());
      if (value && value.code) code = value.code;
    }
    return code;
  }
  getCodeByStaticCode(list: any[], staticCode: string): string {
    let code = "";
    if (list && list.length > 0 && staticCode) {
      let value = list.find((i) => i.staticCode.toLowerCase() == staticCode.toLowerCase());
      const othersValue: string = STATICCODESERVICE.OTHERS;
      if(!value) value = list.find((i) => i.systemCode.toLowerCase() == othersValue.toLowerCase());
      if (value && value.code) code = value.code;
    }
    return code;
  }

  epochDateConvertor(dateFormat: any, timeFormat: string) {
    let dateFormatted: any;
    if (timeFormat == "start") {
      dateFormatted = new Date(new Date(dateFormat).setHours(0, 0, 0, 0));
    } else {
      dateFormatted = new Date(new Date(dateFormat).setHours(23, 59, 0, 0));
    }
    const epochDate = Math.round(dateFormatted.getTime() / 1000);
    return epochDate;
  }

  getContractObject(contracts: any) {
    const contractData: ContractModel = new ContractModel();
    contractData.contractId = contracts.masterId;
    contractData.contractCode = contracts.code;
    contractData.contractName = contracts.desc;
    contractData.effectiveStartDate = contracts.effectiveStartDate;
    contractData.effectiveEndDate = contracts.effectiveEndDate;
    contractData.senderTypeId = contracts.senderTypeMaster.id;
    contractData.companyDesc = contracts.companyTypeDesc;
    contractData.fallBackTariffCode =
      contracts.configurationList[
        contracts.configurationList.length - 1
      ].fallBackTariffCode;
    contractData.fallBackTariffDesc =
      contracts.configurationList[
        contracts.configurationList.length - 1
      ].fallBackTariffDesc;
    contractData.coSharePercentage =
      contracts.configurationList[
        contracts.configurationList.length - 1
      ].coSharePercentage;
    contractData.status =
      contracts.active && contracts.active == true ? "A" : "";
    return contractData;
  }

  /**
   *Set pateintSavePayLoad method
   *
   * @param {*} _this
   * @param {*} patientData
   * @return {*}
   * @memberof PatientRegistrationService
   */
  setSavePayload(_this, patientData) {
    this.pateintDataSet.primaryIdentifierList = [];
    this.pateintDataSet.secondaryIdentifierList = [];
    this.pateintDataSet.extendedAddress = new ExtendedAddress();
    this.pateintDataSet.correspondingAddress = new ExtendedAddress();
    this.pateintDataSet.motherReference = new motherReference();
    this.pateintDataSet.deceasedPatient = new deceased();
    this.pateintDataSet.mobileNumber = new mobileNumber();
    this.pateintDataSet.patientNotes = [];
    this.pateintDataSet.employeeDetails = new employeeDetails();
    let patientTypeList=[];
   

    const primaryIdentiferValue = this.langService.createObjectList(
      patientData.identificationTypePrimary.desc
        ? patientData.identificationTypePrimary.desc
        : patientData.identificationTypePrimary.code
    );
    let secondaryIdType = this.getObjByCode(
      _this.secondaryIdList,
      patientData.identificationTypeSecondary.code
    );
   
    const secondaryIdentifierValue = patientData.identificationTypeSecondary
      .code
      ? this.langService.createObjectList(secondaryIdType)
      : null;

  let isMyKad = (patientData.identificationTypePrimary && patientData.identificationTypePrimary.desc) ? patientData.identificationTypePrimary.desc.isMyKad : false;
  if(isMyKad && patientData.identificationNumberPrimary && patientData.identificationNumberPrimary.includes("-")) patientData.identificationNumberPrimary = patientData.identificationNumberPrimary.split("-").join("");
  
  let registerAgainst=secondaryIdentifierValue&&secondaryIdentifierValue[0]?(secondaryIdentifierValue[0].code=="Staff"?OPD_CONST.staffType:OPD_CONST.studentType):OPD_CONST.patientType;
    this.pateintDataSet.primaryIdentifierList = primaryIdentiferValue?[
      {
        system: primaryIdentiferValue[0].system,
        code: primaryIdentiferValue[0].code,
        display: primaryIdentiferValue[0].display,
        value: patientData.identificationNumberPrimary,
        status: true,
        expiryDate: new Date(patientData.expiryDate),
        issuingCountryList: patientData.issuedCountryPrimary
          ? this.langService.createObjectList(
              patientData.issuedCountryPrimary.code
            )
          : null,
      },
    ]:[];

    this.pateintDataSet.secondaryIdentifierList = patientData
      .identificationTypeSecondary.code
      ? [
          {
            system: secondaryIdentifierValue[0].system,
            code: secondaryIdentifierValue[0].code,
            display: secondaryIdentifierValue[0].display,
            value: patientData.identificationNumberSecondary,
            status: true,
            expiryDate: new Date(patientData.seondaryExpiryDate),
            issuingCountryList: this.langService.createObjectList(
              patientData.issuedCountryPrimary.code
            ),
            staffReferenceId:patientData.id
          },
        ]
      : null;
    (this.pateintDataSet.patientIdentifierList = OPD_CONST.patientIdetifier),
      (this.pateintDataSet.prefixList = this.langService.createObjectList(
        patientData.prefixTitle.code
      )),
      (this.pateintDataSet.patientName = patientData.fullName),
      (this.pateintDataSet.firstName = ""),
      (this.pateintDataSet.middleName = ""),
      (this.pateintDataSet.lastName = ""),
      (this.pateintDataSet.familyName = ""),
      (this.pateintDataSet.genderList = this.langService.createObjectList(
        patientData.gender.code
      )),
      (this.pateintDataSet.martialStatusList =
        this.langService.createObjectList(patientData.martialStatus.code)),
      (this.pateintDataSet.dateOfBirth = patientData.dateOfBirth),
      (this.pateintDataSet.birthTime = patientData.birthTime),
      (this.pateintDataSet.deceasedPatient.deceased = patientData.isDeceased);
    this.pateintDataSet.deceasedPatient.markedBy = "";
    this.pateintDataSet.deceasedPatient.markedOn = null;
    this.pateintDataSet.real = patientData.realdob;
    this.pateintDataSet.raceList = this.langService.createObjectList(
      patientData.race.code?patientData.race.code[0]:null
    );
    this.pateintDataSet.employeeStatusList = this.langService.createObjectList(
      patientData.employmentStatus.code
    );

    this.pateintDataSet.patientCategoryList = this.langService.createObjectList(
      patientData.patientCategory.code
    );
    (this.pateintDataSet.preferedLanguageList =
      this.langService.createObjectList(patientData.preferredLanguage.code)),
      (this.pateintDataSet.nationalityList = this.langService.createObjectList(
        patientData.nationality.code
      ));
    (this.pateintDataSet.educationList = this.langService.createObjectList(
      patientData.education.code
    )),
      (this.pateintDataSet.occupationList = this.langService.createObjectList(
        patientData.occupation.code
      ));
    this.pateintDataSet.ethnicList = this.langService.createObjectList(
      patientData.ethnic.code
    );
    this.pateintDataSet.religionList = this.langService.createObjectList(
      patientData.religion.code
    );
    (this.pateintDataSet.birthLocation = patientData.birthplace),
    (this.pateintDataSet.tinNo = patientData.tinNo),
      (this.pateintDataSet.motherName = patientData.motherName),
      (this.pateintDataSet.facultyList = this.langService.createObjectList(
        patientData.faulty.code
      )),
      (this.pateintDataSet.bloodGroupList = this.langService.createObjectList(
        patientData.bloodGroup.code
      )),
      (this.pateintDataSet.blocked = patientData.isBlock),
      (this.pateintDataSet.ctos = patientData.isCtos),
      (this.pateintDataSet.extendedAddress.addressLine =
        patientData.addressPrimary);
    (this.pateintDataSet.extendedAddress.countryList =
      this.langService.createObjectList(patientData.countryPrimary.code)),
      (this.pateintDataSet.extendedAddress.stateList =
        this.langService.createObjectList(patientData.statePrimary.code)),
      (this.pateintDataSet.extendedAddress.cityList =
        this.langService.createObjectList(patientData.cityPrimary.code));
    (this.pateintDataSet.extendedAddress.areaList =
      this.langService.createObjectList(patientData.subDistrictPrimary.code)),
      (this.pateintDataSet.extendedAddress.postCode =
        patientData.postcodePrimary);
    this.pateintDataSet.extendedAddress.addressLine =
      patientData.addressPrimary;
    (this.pateintDataSet.correspondingAddress.countryList =
      this.langService.createObjectList(patientData.countrySecondary.code)),
      (this.pateintDataSet.correspondingAddress.stateList =
        this.langService.createObjectList(patientData.stateSecondary.code)),
      (this.pateintDataSet.correspondingAddress.cityList =
        this.langService.createObjectList(patientData.citySecondary.code)),
      (this.pateintDataSet.correspondingAddress.areaList =
        this.langService.createObjectList(
          patientData.subDistrictSecondary.code
        )),
      (this.pateintDataSet.correspondingAddress.postCode =
        patientData.postcodeSecondary);
    this.pateintDataSet.correspondingAddress.addressLine =
      patientData.addressSecondary;
    this.pateintDataSet.sameAddress = false;
    this.pateintDataSet.newBornBaby = patientData.isNewborn;
    (this.pateintDataSet.motherReference.referenceId = ""),
      (this.pateintDataSet.motherReference.referenceName = ""),
      (this.pateintDataSet.motherReference.referenceNumber = ""),
      (this.pateintDataSet.contactRelationList =
        this.langService.createObjectList(patientData.relation.code));
    this.pateintDataSet.vip = patientData.isVip;
    let temppatient = _this.tempPatientRef
      ? _this.tempPatientRef.code
        ? {
            system: _this.tempPatientRef.system,
            code: _this.tempPatientRef.code,
            display: _this.tempPatientRef.display,
          }
        : null
      : null;
    (this.pateintDataSet.temporaryPatientReference = temppatient),
      (this.pateintDataSet.personalFax = patientData.personalFax),
      (this.pateintDataSet.workFax = patientData.workFax),
      this.pateintDataSet.registerAgainst=registerAgainst;
      (this.pateintDataSet.countryCode =
        patientData.countryCodemobile && patientData.countryCodemobile.code && patientData.countryCodemobile.code.code !== null
        ? patientData.countryCodemobile.code.code
        : ""),
      (this.pateintDataSet.patientID = "");
    this.pateintDataSet.photo = {
      contentType: "image/jpeg",
      url: _this.patientImage,
    };
    (this.pateintDataSet.emailId = patientData.email),
      (this.pateintDataSet.homeContactNo = patientData.homeContact);
    this.pateintDataSet.workContactNo = patientData.workContact;

    if(patientData.mobile && patientData.mobile.includes("-")) patientData.mobile = patientData.mobile.replace("-","");
    this.pateintDataSet.mobileNumber.mobileNumber = patientData.mobile;
    this.pateintDataSet.mobileNumber.countryCode =
      patientData.countryCodemobile && patientData.countryCodemobile.code && patientData.countryCodemobile.code.code !== null
      ? patientData.countryCodemobile.code.code
      : "";
    this.pateintDataSet.mobileNumber.relationList =
      this.langService.createObjectList(patientData.relation.code);
    this.pateintDataSet.patientNotes = [
      {
        note: patientData.patientNotes,
        addedOn: null,
        addedBy: "",
        status: true,
      },
    ];
    this.pateintDataSet.source = this.langService.createObjectList(
      patientData.sourceType.code
    );

    let patientEmployeeDetails = this.pateintDataSet.employeeDetails;
    patientEmployeeDetails.employmentStatus = this.langService.createObjectList(
      patientData.employmentStatus ? patientData.employmentStatus.code : null
    );
    patientEmployeeDetails.employer = this.langService.createObjectList(
      patientData.employerName ? patientData.employerName.code : null
    );
    patientEmployeeDetails.other = patientData.employerNameOthers
      ? patientData.employerNameOthers
      : "";
    patientEmployeeDetails.employmentNo = patientData.employmentNo
      ? patientData.employmentNo
      : "";
    patientEmployeeDetails.contactNo = patientData.employmentContactNo
      ? patientData.employmentContactNo
      : "";
    patientEmployeeDetails.workingFrom = patientData.workingFrom
      ? patientData.workingFrom.valueOf()
      : null;
    patientEmployeeDetails.employeeAdress = {
      addressLine: patientData.employmentAddress
        ? patientData.employmentAddress
        : "",
      postCode: patientData.employmentPostCode
        ? patientData.employmentPostCode
        : "",
      countryList: this.langService.createObjectList(
        patientData.employmentCountry
          ? patientData.employmentCountry.code
          : null
      ),
      stateList: this.langService.createObjectList(
        patientData.employmentState ? patientData.employmentState.code : null
      ),
      cityList: this.langService.createObjectList(
        patientData.employmentCity ? patientData.employmentCity.code : null
      ),
      areaList: null,
    };

    if (patientData.id) {
      this.pateintDataSet.primaryIdentifierListModified =
        _this.primaryIdentifierListModified
          ? _this.primaryIdentifierListModified
          : false;
      this.pateintDataSet.secondaryIdentifierListModified = false;
    }
    this.pateintDataSet.isStaffActive=patientData.active;
    this.pateintDataSet.extendedAdmissionRequestId=_this.admissionReqload?_this.admissionReqload.id:'';
    return this.pateintDataSet;
  }

  /**
   *set encounter save payload
   *
   * @param {*} _this
   * @param {*} patientData
   * @param {*} payerData
   * @param {*} kinData
   * @return {*}
   * @memberof PatientRegistrationService
   */
  setEncounterSave(_this, patientData, payerData, kinData) {
    let packageList = [];
    if (_this.selectedOption && _this.selectedOption.length > 0) {
      for (let i = 0; i < _this.selectedOption.length; i++) {
        let value = _this.healthPackageList.find(
          (s) => s.code == _this.selectedOption[i]
        );
        let res = this.langService.createObjectList(value);
        packageList.push(res[0], res[1]);
      }
    }
    if(_this.pdpaForm == true){
      this.pdpaForm = false;
    }else{
      this.pdpaForm = true;
    }
    let payload = {
      extendedEncounter: {
        status: OPD_CONST.encounterSave.status,
        businessStatus: OPD_CONST.businessStatusList.ARRIVED,
        doctorList: this.langService.createObjectList(patientData.doctor.desc),
        defaultDeposit: patientData.defaultDeposit,
        sourceList: null,
        departmentList: this.langService.createObjectList(
          patientData.department.code
        ),
        encounterReferral: {
          encounterReferralList: this.langService.createObjectList(
            patientData.referral.code
          ),
          encounterReferralRemark: patientData.referralRemark,
          encounterReferralTypeList: this.langService.createObjectList(
            patientData.referralType.code
          ),
        },
        encounterRemarks: patientData.remark,
        encounterToken: _this.tokenNoValue,
        cashCounterList: this.langService.createObjectList(
          patientData.cashCounter.code
        ),
        appointmentReference: {
          system: "EN",
          code: _this.appointmentReference
            ? _this.appointmentReference.appointmentId
            : "",
          display: _this.appointmentReference
          ? _this.appointmentReference.appointmentNo
          : "",
        },
        consultationTypeList: null,
        patientNotesUpdated: _this.patientNotesUpdated
          ? _this.patientNotesUpdated
          : false,
        patientNoteList: [
          {
            note: patientData.reportRemarks,
            addedOn: null,
            addedBy: null,
            status: true,
          },
        ],

        encounterNoteList: _this.internalNotesList
          ? _this.internalNotesList
          : [],
        practice: patientData.practiceNotes ? patientData.practiceNotes : "",
        diagnosisNote: "",
        extTemperature: "",
        extAmountofSample: "",
        extReferredBy: "",
        encounterTypeList: this.langService.createObjectList(
          patientData.encounterType.code
        ),
        patientCategoryList: this.langService.createObjectList(
          patientData.patientCategory.code
        ),
        visitReasonTypeList: this.langService.createObjectList(
          patientData.visittype.code
        ),
        report: null,
        visitType:patientData.visittype.code=='ER'?OPD_CONST.erVisittype:OPD_CONST.visitType,
        clinicList: this.langService.createObjectList(patientData.clinic.code),
        financialClassList: OPD_CONST.financialList,
        cancelDetail: null,
        patientMealPreferenceList: null,
        patientMealAttenderList: null,
        doctorDetailList: null,
        bedComponent: null,
        packageList: packageList ? packageList : [],
        encounterwithSlotDetail: _this.appointmentData
          ? _this.appointmentData
          : null,
        qmsPackageId: patientData.department.code
          ? patientData.department.code.qmsPackageMaster
            ? patientData.department.code.qmsPackageMaster.id
            : null
          : null,
        docPrefix: patientData.doctor.desc
          ? patientData.doctor.desc.docPrefix
            ? patientData.doctor.desc.docPrefix
            : null
          : null,
          deptPrefix: patientData.department && patientData.department.code && patientData.department.code.departmentPrefix
          ? patientData.department.code.departmentPrefix
          : null,
        medicalReportsDetails: _this.medicalReport
          ? {
              reportType: this.langService.createObjectList(
                patientData.reportType.code
              ),
              reportReason: this.langService.createObjectList(
                patientData.reportReason.desc
              ),
              reportRemarks: patientData.reportRemarksMrd
                ? patientData.reportRemarksMrd
                : "",
            }
          : null,
           isPdpaRequired : this.pdpaForm,
      },

      payeeDTOList: this.getPayerTariffList(payerData),
      kinDetailsDTOList: this.getNextOfKinList(kinData),
      extendedPatient: {
        code: OPD_CONST.encounterSave.id,
        display: _this.patientId,
      },
      pendingFutureOrderRequestList:this.getpendingOrders(_this.groupedItems)
    };
    return payload;
  }

  /**
   *get payer Details
   *
   * @param {*} payerData
   * @return {*}
   * @memberof PatientRegistrationService
   */
  getPayerTariffList(payerData) {
    let payeeDTOList: any[] = [];
    payerData.forEach((element: PatientEncounterPayer, index) => {
      payeeDTOList.push({
        payerTypeList: this.langService.createObjectList(element.payerTypeCode),
        companyList: this.langService.createObjectList(element.payerDesc),
        payerDetailDTO: null,
        tariffList: this.langService.createObjectList(element.tariffDesc),
        contractList: this.langService.createObjectList(element.contractDesc),
        associatedCompanyList: this.langService.createObjectList(
          element.associatedCompanyDesc
        ),
        glTypeList: this.langService.createObjectList(element.glTypeDesc),
        glEffectiveDate: element.effectiveDate ?? null,
        insuranceNumber: element.policyNumber,
        glReferenceNo:element.glReferenceNo,
        expiryDate:element.expiryDate ?? null,
        priority: Number(index + 1),
        status: OPD_CONST.encounterSave.active,
      });
    });

    return payeeDTOList;
  }

  /**
   *get next kin details
   *
   * @param {*} kinData
   * @return {*}
   * @memberof PatientRegistrationService
   */
  getNextOfKinList(kinData) {
    let kinDetailsDTOList: any[] = [];
    if (kinData != null) {
      kinData.forEach((element: PatientNextOfKin) => {
        kinDetailsDTOList.push({
          id:element.id?element.id:null,
          active:element.active?element.active:false,
          name: element.name,
          idNo: element.idNumber,
          mobileNo: element.phoneNumber,
          prefixList: this.langService.createObjectList(element.prefix?element.prefix.code:""),
          identificationTypeList: this.langService.createObjectList(
            element.identificationType.code?element.identificationType.code:""
          ),
          guarantor: element.isGuarantor ? element.isGuarantor : false,
          address: {
            countryList: this.langService.createObjectList(
              element.country.code
            ),
            cityList: this.langService.createObjectList(element.city.code),
            stateList: this.langService.createObjectList(element.state.code),
            postCode: element.postcode,
            addressLine: element.address,
          },
          relationList: this.langService.createObjectList(
            element.relation.code
          ),
        });
      });
    }

    return kinDetailsDTOList;
  }

  getpendingOrders(orderList) {
    let list=[];
    for (const groupName in orderList) {
      if (orderList.hasOwnProperty(groupName)) {
        orderList[groupName].forEach(obj => {
          if (obj.isSelected === true) {
            list.push(
              {"doctorOrderId":obj.doctorOrderId})
          }
        });
      }
    }
    return list;
  }
  /**
   * To transform newly added payer to API's format
   * @param payerList
   * @return {*}
   * @memberof PatientRegistrationService
   */
  payerTransform(payer) {
    let item = payer;
    let object = {
      id: null,
      payerType: {
        system: "EN",
        code: item.payerTypeCode,
        display: item.payerTypeName,
      },
      payer: {
        system: "EN",
        code: item.payercode,
        display: item.payersname,
      },
      tariff: {
        system: "EN",
        code: item.tariffcode,
        display: item.tariffname,
      },
      contract: {
        system: "EN",
        code: item.contractCode,
        display: item.contractName,
      },
      associatedCompany: {
        system: "EN",
        code: item.AssociateCompandCode,
        display: item.associatedCompanyName,
      },
      glType: {
        system: "EN",
        code: item.glTypeCode,
        display: item.glTypeName,
      },
      policyNo: item.policyNo,
      effectiveDate: item.effectiveDate,
      expiryDate: null,
      priority: null,
      status: item.status,
    };
    return object;
  }

  /**
   *
   * To generate update payer payload
   * @param payerList
   * @return payload
   * @memberof PatientRegistrationService
   */
  generatepayerUpdatePayload(payer, encounterId) {
    let item = payer;
    let object = {
      encounterId: encounterId,
      id: item.id,
      payerTypeList: this.langService.createObjectList(item.payerType),
      payerDetailDTO: null,
      companyList: this.langService.createObjectList(item.payer),
      tariffList: this.langService.createObjectList(item.tariff),
      contractList: this.langService.createObjectList(item.contract),
      associatedCompanyList: this.langService.createObjectList(
        item.associatedCompany
      ),
      glTypeList: this.langService.createObjectList(item.glType),
      insuranceNumber: item.policyNo,
      glEffectiveDate: item.effectiveDate,
      endDate: null,
      priority: item.priority,
      status: item.status == "Active" ? "Active" : "InActive",
    };
    return object;
  }
  /**
   *
   *
   * @param {any[]} healthTouristDetails
   * @return {*} 
   * @memberof PatientRegistrationService
   */
  addHealthTouristDetails(healthTouristDetails: any[]) {
    let result = [];
    healthTouristDetails.forEach((element) => {
     
      let obj = 
        {
          healthTouristCode: element.code?element.code:"",
          agentIdentificationType: {
            system: "EN",
            code: element.identificationType? element.identificationType.code:"",
            display:  element.identificationType? element.identificationType.desc:"",
          },
          agentIdentificationNo: element.identificationNumber?element.identificationNumber:"",
          agentName: element.agentName?element.agentName:"",
          startDate: this.epochConverter(element.startDate),
          endDate: this.epochConverter(element.endDate),
          contactNo: element.contactNo?element.contactNo:"",
          emailId: element.emailId?element.emailId:"",
          healthTouristAddress: {
            addressLine: element.address?element.address:"",
            postCode: element.postcode?element.postcode:"",
            countryList: [
              {
                system: "EN",
                code: element.country?element.country.code:"",
                display: element.country?element.country.desc:"",
              },
              {
                system: "MY",
                code: element.country?element.country.code:"",
                display: element.country?element.country.desc:"",
              },
            ],
            stateList: [
              {
                system:"EN",
                code: element.state?element.state.code:"",
                display: element.state?element.state.desc:"",
              },
              {
                system: "MY",
                 code: element.state?element.state.code:"",
                display: element.state? element.state.desc:"",
              },
            ],
            cityList: [
              {
                system:"EN",
                code: element.city?element.city.code:"",
                display: element.city?element.city.desc:"",
              },
              {
                system: "MY",
                 code: element.city?element.city.code:"",
                display: element.city?element.city.desc:"",
              },
            ],
          },
        }
      
      result.push(obj);
    });
    this.pateintDataSet.healthTouristDetailsDTO = result;
  }
  epochConverter(strDate) {
    if (strDate) {
      const datum = Date.parse(strDate);
      const epoch = datum / 1000;
      return epoch;
    } else return "";
  }
}
