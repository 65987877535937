import { Injectable } from "@angular/core";
import { AppBaseService } from "./http.service";
import { Observable,of  } from "rxjs";
import { MICROSERVICES } from "../constants/web-services";
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class ApplicationConfigService {
  moduleConfigurationList: ModuleConfiguration[];
  screenConfigurationList: ScreenConfiguration[];
  organizationConfigurationList: OrganizationConfiguration[];
  screenConfiguration = {
    listOfResources: []
  };

  constructor(private httpService: AppBaseService) {}

  // getModuleConfiguration(moduleName: string, keyName?: string,isOrg?:boolean) {
  //   return new Observable(observer => {
  //     let list = this.moduleConfigurationList;
  //     if (list && list.length > 0) {
  //       observer.next(this.moduleConfigurationList);
  //     } else {
  //       let unitCode =isOrg?null: atob(localStorage.getItem("unitCode"));
  //       let orgCode = atob(localStorage.getItem("orgCode"));
  //       this.httpService.setResourceURL(MICROSERVICES.APP_CONFIG_SERVICE);
  //       return this.httpService
  //         .getResource(
  //           "appConfiguration/findConfigByModule?unitCode=" +
  //             unitCode +
  //             "&orgCode=" +
  //             orgCode +
  //             "&moduleName=" +
  //             moduleName +
  //             "&keyName=" +
  //             keyName
  //         )
  //         .subscribe(response => {
  //           if (response && response.keys) {
  //             this.moduleConfigurationList = response.keys;
  //             observer.next(this.moduleConfigurationList);
  //           }
  //         });
  //     }
  //   });
  // }
  getModuleConfiguration(moduleName: string, keyName?: string, isOrg: boolean = false): Observable<any[]> {
    const configurationList = isOrg ? this.organizationConfigurationList : this.moduleConfigurationList;
    
    if (configurationList && configurationList.length > 0) {
      return of(configurationList);
    } else {
      const unitCode = isOrg ? null : atob(localStorage.getItem("unitCode"));
      const orgCode = atob(localStorage.getItem("orgCode"));
  
      this.httpService.setResourceURL(MICROSERVICES.OPD_SERVICE);
  
      return this.httpService.getResource(
        "appConfiguration/findConfigByModule?unitCode=" +
              unitCode +
              "&orgCode=" +
              orgCode +
              "&moduleName=" +
              moduleName +
              "&keyName=" +
              keyName
      ).pipe( map(response => {
          if (response && response.keys) {
            this.updateConfigurationList(response.keys, isOrg);
            return response.keys;
          }
          return [];
        }),catchError(error => {
          return of([]);
        })
      );
    }
  }
  
  private updateConfigurationList(keys: any[], isOrg: boolean): void {
    if (isOrg) {
      this.organizationConfigurationList = keys;
    } else {
      this.moduleConfigurationList = keys;
    }
  }

  getScreenConfiguration(
    moduleName: string,
    subModuleName: string,
    subModuleChildName?: string,
    keyName?: string
  ) {
    return new Observable(observer => {
      let unitCode = atob(localStorage.getItem("unitCode"));
      let orgCode = atob(localStorage.getItem("orgCode"));
      let configurationKeys = this.getKeyList(moduleName, subModuleName);

      if (configurationKeys && configurationKeys.length == 0) {
        this.httpService.setResourceURL(MICROSERVICES.OPD_SERVICE);
        this.httpService
          .postResource("screenAppConfig/advancedSearch", {
            unitCode: unitCode,
            orgCode: orgCode,
            moduleName: moduleName,
            submodule: subModuleName,
            submoduleChild: subModuleChildName ? subModuleChildName : "",
            groupName: "",
            key: keyName? keyName: ""
          })
          .subscribe(response => {
            let screenConfigurationList = [];
            if (response && response.listOfResources) {
              this.screenConfiguration = response;
              screenConfigurationList = this.getKeyList(
                moduleName,
                subModuleName
              );
            }
            observer.next(screenConfigurationList);
          });
      } else {
        observer.next(configurationKeys);
      }
    });
  }

  getKeyList(moduleName: string, subModuleName: string): any[] {
    let configurationKeys = [];
    if (this.screenConfiguration.listOfResources.length > 0) {
      let configuration: any = this.screenConfiguration.listOfResources.filter(
        i => i.module == moduleName
      );
      if (configuration && configuration.length > 0 && configuration[0].keys) {
        configurationKeys = configuration[0].keys.filter(
          i => i.submodule == subModuleName
        );
      }
    }

    return configurationKeys;
  }
  
  getOrganizationConfiguration(moduleName: string) {
    return new Observable(observer => {
      // let list = this.organizationConfigurationList;
      // if (list && list.length > 0) {
      //   observer.next(this.organizationConfigurationList);
      // } else {
        // let unitCode = atob(localStorage.getItem("unitCode"));
        let orgCode = atob(localStorage.getItem("orgCode"));
        this.httpService.setResourceURL(MICROSERVICES.OPD_SERVICE);
        return this.httpService
          .getResource(
            "appConfiguration/findConfigByModuleAndORgCode?moduleName=" +
            moduleName +
              "&orgCode=" +
              orgCode 
          )
          .subscribe(response => {
            if (response) {
              this.organizationConfigurationList = response;
              observer.next(this.organizationConfigurationList);
            }
          });
      // }
    });
  }
}

export class ModuleConfiguration {
  key: string;
  optionList: [];
  submodule: string;
  type: string;
  url: string;
  valueList: any[];
}

export class ScreenConfiguration {
  groupName: string;
  key: string;
  showInScreen: boolean;
  submodule: string;
  submoduleChild: string;
  systemMandatory: boolean;
  userSelected: boolean;
  validationRegex: string;
}

export class OrganizationConfiguration {
  key: string;
  optionList: [];
  submodule: string;
  type: string;
  url: string;
  valueList: any[];
}