import { Injectable } from '@angular/core';
import localForage from "localforage";
import { AppBaseService } from "./http.service";
import { MICROSERVICES } from "./../constants/web-services";

@Injectable({
  providedIn: 'root'
})
export class MasterService {
    constructor(private httpService: AppBaseService) { }

    /**
     * To set local forage data
     * @param name 
     * @param data 
     * @returns setItem
     */
    private setLocalForage(name, data){
        return localForage.setItem(name, data);
    }

    /**
     * To get localforage value
     * @param name 
     * @returns getItem
     */
    private getLocalForage(name){
        return localForage.getItem(name);
    }

    /**
     * To clear local forage
     * @param name 
     */
    private clearLocalForage(name){
        localForage.removeItem(name);
    }

    /**
     * To clear entire local forage
     */
    private clearAll(){
        localForage.clear()
    }

    /**
     * To get master data.
     * If available in local forage, it will fetch from there otherwise from API and store it in local forage
     * @param name 
     * @returns promise
     */
    public getMaster(name){
        return new Promise((resolve, reject) => {
            this.getLocalForage(name).then(data => {
                if(data){
                    // resolve(data);
                    this.getList(name).subscribe({
                        next: (res) => {
                            this.setLocalForage(name, res).then(data => {
                                resolve(data);
                            });
                        },
                        error: (err) => {
                            reject(err);
                        }
                    })
                }else{
                    this.getList(name).subscribe({
                        next: (res) => {
                            this.setLocalForage(name, res).then(data => {
                                resolve(data);
                            });
                        },
                        error: (err) => {
                            reject(err);
                        }
                    })
                }
            })
        });
    }

    /**
     * To get master data with params
     * @param name 
     * @param params 
     * @returns promise
     */
    public getMasterWithParams(name, params){
        return new Promise((resolve, reject) => {
            let urlParams = "?";
            for (let key in params) {
                urlParams += [key]+"="+[params[key]]+"&";
            }
            let url = (name+urlParams).slice(0, -1);
            this.getList(url).subscribe({
                next: (res) => {
                    resolve(res);
                },
                error: (err) => {
                    reject(err);
                }
            });
        })
    }

    /**
     * To get master data from API
     * @param name 
     * @returns httpclient get
     */
    private getList(name,useCache: boolean = true) {
        this.httpService.setResourceURL(MICROSERVICES.MASTERS_SERVICE);
        return this.httpService.getResource(name,useCache)
    }
}
