export const MASTERSSERVICES = {
    ADMISSION_TYPE_MASTER: "Masters/AdmissionTypeMaster",
    DEPOSIT_TYPE_MASTER: "Masters/DepositTypeMaster",
    ADMISSION_REASON_MASTER: "Masters/AdmissionReasonMaster",
    DEPT_GET_DEPT_BY_UNIT: "department/getDeptByUnit",
    DEPT_GET_DEPT_BY_UNIT_CODE: "dept/getDeptByUnitCode",
    PREFIX_MASTER: "Masters/PrefixMaster",
    IDENTIFICATION_TYPE_MASTER: "Masters/IdentificationTypeMaster",
    REFERRAL_TYPE_MASTER: "Masters/ReferralTypeMaster",
    REFERRAL_MASTER: "Masters/ReferralMaster/query",
    COUNTRY_MASTER: "Masters/CountryMaster",
    COUNTRY_CODE_MASTER: "Masters/CountryCodeMaster",
    STATE_MASTER: "Masters/StateMaster",
    CITY_MASTER: "Masters/CityMaster",
    STATE_MASTER_QUERY: "Masters/StateMaster/query",
    CITY_MASTER_QUERY: "Masters/CityMaster/query",
    DIST_MASTER_QUERY: "Masters/AreaMaster/query",
    RELATION_MASTER: "Masters/RelationMaster",
    WARD_MASTER: "wardMaster/get",
    FINANCIAL_CLASS_MASTER: "Masters/FinancialClassMaster",
    PATIENT_SOURCE_MASTER: "Masters/PatientSourceMaster",
    MEAL_PREFERENCE: "Masters/MealTypeMaster",
    BED_GET_BED_LIST: "bed/getBedList/",
    EMPLOYEE_LIST: "employee/list/",
    M_EMPLOYEE_LIST:"employee/employeeList",
    EMPLOYEE_OTHER_LIST:"employee/OthersList",
    EMPLOYEE_GET_ALL: "employee/getAll",
    EMPLOYEE_GET_BY_TYPE_DOCTOR: "employee/getByType?empType=1",
    EMPLOYEE_DOCTOR_PORTAL: "employee/doctorPortal",
    UNIT_MASTER: "Masters/UnitMaster",
    GENDER_MASTER: "Masters/GenderMaster",
    BED_CATEGORY: "Masters/BedCategoryMaster", 
    ROOM_MASTER:"Masters/RoomMaster",
    COMPANY_TYPE_MASTER: "Masters/CompanyTypeMaster",
    BED_STATUS: "Masters/BedStatus",
    FLOOR_MASTER:"floor/get",
    BLOCK_MASTER: "Masters/BlockMaster",
    BED_GET_ALL_BED_LIST: "bed/getAllBedList",
    FC_REASON: "Masters/FCReason",
    CURRENCY_MASTER: "Masters/CurrencyMaster",
    BANK_MASTER: "Masters/BankMaster",
    CARD_TYPE_MASTER: "Masters/CardTypeMaster",
    TRANS_TYPE_MASTER: "Masters/TransTypeMaster",
    PAYMENT_MODE_MASTER: "Masters/PaymentModeMaster",
    GL_TYPE_MASTER: "Masters/GLTypeMaster",
    TARIFF_MASTER: "Masters/TariffMaster",
    VISIT_TYPE_MASTER: "Masters/VisitTypeMaster",
    PATIENT_ABSCOND_STATUS_MASTER: "Masters/PatientAbscondStatusMaster",
    DEPOSIT_MATRIX_QUERY: "DepositMatrixMaster/list",
    CLASS_BY_PACKAGE: "Masters/ClassByPackage",
    NOTIFICATION_TYPE_MASTER: "Masters/NotificationTypeMaster",
    CASH_COUNTER_MASTER: "Masters/CashCounterMaster",
    EMPLOYEE_TYPE_MASTER: "Masters/EmployeeTypeMaster",
    NURSING_STATION_MASTER: "Masters/NursingStationMaster",
    SUB_DEPARTMENT_MASTER: "Masters/SubDepartmentMaster",
    LOCATION_GET_LOCATION_LIST_BY_UNIT: "location/getLocationListByUnit/",
    MARITAL_MASTER: "Masters/MaritalStatusMaster",
    RELIGION_MASTER: "Masters/ReligionMaster",
    PATIENT_CATEGORY_MASTER: "Masters/PatientCategoryMaster",
    LANGUAGE_MASTER: "Masters/LanguageMaster",
    NATIONALITY_MASTER: "Masters/NationalityMaster",
    EDUCATION_MASTER: "Masters/QualificationMaster",
    OCCUPATION_MASTER: "Masters/OccupationMaster",
    RACE_MASTER: "Masters/RaceMaster",
    ETHNIC_MASTER:"Masters/EthnicityMaster",
    BLOOD_GROUP_MASTER: "Masters/BloodGroupMaster",
    ENCOUNTER_TYPE_MASTER: "Masters/EncounterTypeMaster",
    CABIN_MASTER:"cabinMaster/getCabinMaster",
    VISIT_REASON_MASTER:"Masters/VisitReasonMaster",
    COMPANY_MASTER:"Masters/CompanyMaster",
    LANGUAGE_CONFIGURATION_MASTER: "Masters/LanguageConfigurationMaster",
    APPROVAL_ROLE_LIST: "Masters/approvalRole/list",
    APPROVAL_ROLE_ADD: "Masters/approvalRole/add",
    APPROVAL_ROLE_UPDATE: "Masters/approvalRole/update",
    APPROVAL_ROLE_UPDATE_STATUS: "Masters/approvalRole/updateStatus",
    APPROVAL_ROLE_USER_LIST: "Masters/approvalRole/userList/",
    GET_CONSIGNMENT_STORES: "location/getConsignmentStores/",
    SUPPLIER_MASTER: "Masters/SupplierMaster",
    REASON_MASTER_QUERY: "Masters/ReasonMaster/query",
    GLOBAL_SEARCH_STATUS: "/Masters/GlobalSearchStatus",
    DEACTIVATE_EMPLOYEE: "employee/deActiveEmp/",
    EMPLOYEE_SAVE: "employee/save-employee",
    EMPLOYEE_UPDATE: "employee/update/",
    DELETE_EMPLOYEE_DEPARTMENT: "employee/deleteEmpDept/",
    DELETE_EMPLOYEE_DEPENDANT: "employee/deleteEmpDependent/",
    DELETE_EMPLOYEE_PROFESSION: "employee/deleteEmpPro/",
    DELETE_EMPLOYEE_EDUCATION: "employee/deleteEdu/",
    QUALIFICATION_MASTER: "Masters/QualificationMaster",
    DOCUMENT_TYPE_MASTER: "Masters/DocumentTypeMaster",
    MARITAL_STATUS_MASTER: "Masters/MaritalStatusMaster",
    FACULTY_MASTER: "Masters/FacultyMaster",
    OT_ROLE_MASTER: "Masters/FacultyMaster",
    QUEUE_PREFIX_MASTER: "Masters/QuePrefixMaster",
    DOCTOR_TYPE_MASTER: "Masters/DoctorTypeMaster",
    DAY_MASTER: "Masters/DayMaster",
    THEATRE_ROOM_MASTER_QUERY: "Masters/TheatreRoomMaster/query",
    HOLIDAY_MASTER: "Masters/HolidayMaster",
    HOLIDAY_CALENDAR_MASTER: "holiday-calendar-master/",
    HOLIDAY_CALENDAR_LIST: "holiday-calendar-master/list",
    SCORE_MASTER: "Masters/ScoreMaster",
    STORE_MASTER: "Masters/StoreMaster",
    PAYMENT_TERMS_MASTER: "Masters/PaymentTermsMaster",
    ITEM_UNIT_MASTER: "Masters/ItemUnitMaster",
    SERVICE_PROCUREMENT_MASTER: "Masters/ServiceProcurementMaster",
    OTHER_CHARGES_MASTER: "Masters/OtherChargesMaster",
    DEPARTMENT_MASTER: "Masters/DepartmentMaster/",
    GET_PRIORITY_LIST: "/Inventory/Common/getPriorityList",
    GATE_PASS_AGAINST_MASTER: "Masters/GatePassAgainstMaster",
    GATE_PASS_TYPE_MASTER: "Masters/GatePassTypeMaster",
    ITEM_CATEGORY_MASTER: "Masters/ItemCategoryMaster",
    TAX_MASTER: "Masters/TaxMaster",
    REMINDER_TYPE_MASTER: "Masters/ReminderTypeMaster",
    TEMPLATE_MASTER: "Masters/TemplateMaster",
    TEMPLATE_MASTER_QUERY: "Masters/TemplateMaster/query",
    REMINDER_DURATION_MASTER: "Masters/ReminderDurationMaster",
    ROOM_MASTER_QUERY:"Masters/RoomMaster/query",
    BED_MASTER: "Masters/BedMaster",
    BED_MASTER_GET: "bed/getBedList",
    BED_MASTER_QUERY:"Masters/BedMaster/query",
    BED_MASTER_SAVE: "bed/saveBed",
    BED_MASTER_UPDATE: "bed/updateBed/",
    GROUP_MASTER: "Masters/GroupMaster",
    SUB_GROUP_MASTER: "Masters/SubGroupMaster",
    BILLING_GROUP_MASTER: "Masters/BillingGroupMaster",
    UNIT_SERVICE_MAPPER: "Masters/UnitServiceMapper",
    UNIT_SERVICE_MAPPER_QUERY: "Masters/UnitServiceMapper/query",
    SERVICE_MASTER:"Masters/ServiceMaster",
    HEADER_MASTER: "Masters/HeaderMaster",
    CONTAINER_TYPE_MASTER: "Masters/ContainerTypeMaster",
    CONTAINER_TYPE_GET: "Masters/ContainerTypeMaster/all?includeInactive=true",
    CONTAINER_TYPE_QUERY: "Masters/ContainerTypeMaster/query",
    CONTAINER_TYPE_SAVE: "Masters/ContainerTypeMaster/list",
    CONTAINER_TYPE_UPDATE: "Masters/ContainerTypeMaster/",
    LAB_UNIT_MASTER: "Masters/LabUnitMaster",
    REFERENCE_RANGE_MASTER: "Masters/ReferenceRangeTypeMaster",
    AGE_GROUP_MASTER: "Masters/AgeGroupMaster",
    HELP_VALUE_MASTER: "Masters/HelpValueMaster",
    PRIORITY_MASTER: "Masters/PriorityMaster",
    CLEANING_TYPE_MASTER: "Masters/CleaningTypeMaster",
    CLEANING_TEMPLATE_MASTER: "Masters/CleaningTemplateMaster",
    LOCATION_MASTER_QUERY: "Masters/LocationMaster/query",
    SCHEDULE_MASTER: "Masters/ScheduleMaster",
    WORK_TYPE_MASTER: "Masters/WorkTypeMaster",
    SAMPLE_TYPE_MASTER: "Masters/SampleTypeMaster",
    PARAMETER_MASTER: "Masters/ParameterMaster",
    RESULT_TYPE_MASTER: "Masters/ResultTypeMaster",
    SPECIMEN_TYPE_MASTER: "Masters/SpecimenTypeMaster",
    METHOD_MASTER: "Masters/MethodMaster",
    COLLECTION_TYPE_MASTER: "Masters/CollectionTypeMaster",
    OUTSOURCE_LAB_MASTER: "Masters/OutsourceLabMaster",
    TERMS_AND_CONDITIONS_MASTER: "Masters/TermsAndConditionsMaster/",
    ORGANIZATION_MASTER: "Masters/OrganizationMaster",
    COST_CENTER_MASTER: "Masters/CostCenterMaster",
    DEATH_CERTIFIED_MASTER: "Masters/DeathCertifiedBy",
    FREEZER_MASTER: "Masters/FreezerMaster",
    BODYPART_MASTER: "Masters/BodyPartMaster",
    DESIGNATION_MORTUARY_MASTER: "Masters/DesignationofMortuary",
    DESIGNATION_OF_OTMASTER: "Masters/DesignationofOT",
    BODYCATEGORY_MASTER: "Masters/BodyCategoryMaster",
    FREEZERTYPE_MASTER: "Masters/FreezerType",
    BODYCLAIMED_By: "Masters/BodyClaimedByMaster",
    BED_STATUS_MASTER: "Masters/BedStatusMaster",
    GET_BED_DETAILS_BY_CODE: "extendedEncounter/findByBedCode/", 
    GET_BEDLIST_BY_WARD: "bed/getBedListByWard",
    BIRTH_STATUS_MASTER:"Masters/BirthStatusMaster",
    DELIVERY_TYPE_MASTER:"Masters/TypeOfDeliveryMaster",
    FEEDING_MASTER:"Masters/FeedingMaster",
    ANUSPATIENCY_MASTER:"Masters/AnusPatencyMaster",
    UMBILICAL_CORD_MASTER :"Masters/UmbilicalCordMaster",
    BIRTH_LOCATION_MASTER:"Masters/BirthLocationMaster",
    STOOL_MASTER:"Masters/StoolMaster",
    GET_ROOM_MASTER:"room/get",
    GET_BED_CATEGORY_MASTER:"Masters/BedCategoryMaster",
    EMPLOYMENT_STATUS_MASTER: "Masters/EmploymentStatusMaster",
    REPORTTYPE_MASTER:"Masters/ReportTypeMasterInV",
    REASON_MASTER:"Masters/ReasonMaster/query",
    MASTERS_NOTIFICATION_MASTER: "Masters/NotificationMaster",
    GET_BED_CATEGORY_LIST_BY_WARD: "bed/getBedCategoryListByWard",
    GET_BED_LIST_BY_WARD_AND_CATEGORY: "bed/getBedListByWardAndCategory",
    GET_ADMISSION_SUMMARY: "extendedEncounter/getAdmissionSummary",
    NEWBORN_BED_lIST:"bed/getCradleBedList?bedCode=",
    USER_TYPE_MASTER: "Masters/UserTypeMaster",
    BED_STATUS_MASTERBYSCREEN:"BedStatus/getStatusByScreenId/1",
    EMPLOYEE_LIST_NEW:"employee/employeeList",
    EMP_DOCTOR_PORTAL:"employee/doctorPortal",
    DOCTOR_TYPE:"Masters/DoctorTypeMaster",
    UPDATE_EMPLOYEE_ACTIVE:"employee/updateActiveStatus?",
    GET_EMAIL_ID:"employee/checkEmailId/",
    GET_EMPLOYEE_NO:"employee/checkEmployeeNo/",
    EMP_DOC_TYPE:"employee/getEmployeeTypeListByType",
    EMPLOYEE_TYPE_LIST:"employee/employeeList",
    EMP_MASTER:"Masters/EmployeeTypeMaster",
    M_IDENTIFICATION: "Masters/IdentificationTypeMaster",
    SUB_MASTER:"Masters/TestResultLevelMaster",
    NEW_RACE_MASTER: "appCon/getNationalityWiseRace/",
    M_AGENT_IDENTIFICATION_TYPE:"Masters/AgentIdentificationTypeMaster",
    NATIONALITY_WISE_RACE_MASTER: "appCon/getNationalityWiseRace/",
    M_GRADE:"Masters/GradeMaster",
    GET_CURRENT_DATE: "Masters/getCurrentDate",
    M_DOCUMENT_TYPE:"Masters/DocumentStatusMaster",
    M_DESIGNATION:"Masters/DesignationMaster",
    DESIGNATION_MORTUARY:"Masters/DesignationMaster"
  }; 
