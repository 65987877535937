<div>
    <div class="modal-header modalHeaderBlack">
        <h6 class="modal-title"> Today's Appointment</h6>
        <span
          aria-label="Close"
          class="close"
          (click)="onClick()"
          data-automation-attribute="span-close-dialog"
        >
          <mat-icon matTooltip="{{ 'AddInternalNotes.close' | translate }}"
            >clear</mat-icon
          >
        </span>
      </div>
      
      <div class="divepisodeconversion">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let element">
                <mat-radio-button
                  [value]="element"
                  (change)="onRecordSelect(element)"
                  [checked]="selectedRecord === element"
                >
                </mat-radio-button>
              </td>
            </ng-container>
          
    
            <ng-container matColumnDef="aapNo">
              <th mat-header-cell *matHeaderCellDef> App No </th>
              <td mat-cell *matCellDef="let element"> {{ element.appointmentNo }} </td>
            </ng-container>
          
           
            <ng-container matColumnDef="aapType">
              <th mat-header-cell *matHeaderCellDef> App Type </th>
              <td mat-cell *matCellDef="let element"> {{element.appointmentType ? element.appointmentType.display :""}} </td>
            </ng-container>
          
    
            <ng-container matColumnDef="speciality">
              <th mat-header-cell *matHeaderCellDef> Speciality </th>
              <td mat-cell *matCellDef="let element"> {{ element.speciality ? element.speciality.display:"" }} </td>
            </ng-container>
          
         
            <ng-container matColumnDef="doctor">
              <th mat-header-cell *matHeaderCellDef> Doctor </th>
              <td mat-cell *matCellDef="let element"> {{ element.doctor ?element.doctor.display:"" }} </td>
            </ng-container>
          
    
            <ng-container matColumnDef="package">
              <th mat-header-cell *matHeaderCellDef> Package </th>
              <td mat-cell *matCellDef="let element"> {{ element.packageName ? element.packageName.display :""}} </td>
            </ng-container>
          
         
            <ng-container matColumnDef="appointmentDetails">
              <th mat-header-cell *matHeaderCellDef> Appointment Details </th>
              <td mat-cell *matCellDef="let element"> {{ epochToDateConverter(element.appointmentDetails) | date :"dd/MM/yyyy hh:mm aa"  }} </td>
            </ng-container>
          
      
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let element"> 
                <b class="backgroundColor"  [ngStyle]=" getStatusColor(element.status) " >{{ element.status }}</b> </td>
            </ng-container>
          
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
      </div>
</div>
