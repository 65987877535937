<div class="background-div">
    <div class="header">
        <img class="logo" src="../../../../../assets/images/unsplash/Medcare-Logo.png">
        <div>
            <span class="date-time">{{ formattedDate}}</span>
            <span class="date-time">{{ formattedTime }}</span>
        </div>
    </div>
    
    <div class="content">
        <div class="left-sec" class="img">
            <!-- <img class="img" src="../../assets/MEDCARE.png"> -->
            <iframe width="100%" height="100%"
            src="https://www.youtube.com/embed/1TyvaCBWb40?autoplay=1&mute=1&showinfo=0&loop=1&playlist=1TyvaCBWb40" 
            frameborder="0" 
            allow="autoplay; encrypted-media" >
            </iframe>

            <div *ngIf="currentServingList.length"  class="active-details">
                <ng-container *ngFor="let servingToken of currentServingList">
                    <div class="active-token {{  servingToken && servingToken.tokenNo == tokenNumber ? 'blink' : ''}}">{{ servingToken.tokenNo }}</div>
                    <div class="active-room-counter {{  servingToken && servingToken.tokenNo == tokenNumber ? 'blink' : ''}}">{{ servingToken.counter }}</div>
                </ng-container>
            </div>
        </div>

        <div class="right-sec">
            <div class="grid" *ngIf="mainServingList?.length">
                <div class="grid-item" *ngFor="let data of mainServingList">
                    <div class="token">{{ data.tokenNo }}</div>
                    <div class="room-counter">{{ data.counter ? data.counter:""}}</div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="footer">
        <marquee>
        <span>www.hatiintl.com | +60 0121212121  Monday - Friday 8:00 AM - 8:00 PM | Saturday 09:00 AM - 7:00 PM</span>
        </marquee>
    </div>
</div>
