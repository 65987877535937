export const UICONFIGSERVICE = {
    PATIENT: "Masters/UIConfig/query?field=filterName&value=Patient",
    ADMISSION_DASHBOARD: "UIConfig/query?field=filterName&value=AdmissionDashboard",
    ADMISSION_REQUEST: "UIConfig/query?field=filterName&value=AdmissionRequest",
    ROLE_SEARCH: "UIConfig/query?field=filterName&value=RoleSearch",
    USER_SEARCH: "UIConfig/query?field=filterName&value=UserSearch",
    EMPLOYEE_SEARCH: "UIConfig/query?field=filterName&value=EmployeeSearch",
    SERVICE_SEARCH: "UIConfig/query?field=filterName&value=ServiceMaster",
    SERVICE_TARIFF_SEARCH: "UIConfig/query?field=filterName&value=ServiceTariffRate",
    HOLIDAY_CALENDAR_SEARCH: "UIConfig/query?field=filterName&value=HolidayCalendar",
    DOCTOR_SLOT_SEARCH: "UIConfig/query?field=filterName&value=DoctorSlots",
    DEPARTMENT_SLOT_SEARCH: "UIConfig/query?field=filterName&value=DepartmentSlots",
    TIME_OFF_SEARCH: "UIConfig/query?field=filterName&value=TimeOff",
    ORDER_SET_SEARCH: "UIConfig/query?field=filterName&value=OrderSet",
    BED_CHARGE_SEARCH: "UIConfig/query?field=filterName&value=BedCharge",
    PARAMETER_MASTER_SEARCH: "UIConfig/query?field=filterName&value=ParameterMaster",
    PANEL_MASTER_SEARCH: "UIConfig/query?field=filterName&value=PanelMaster",
    CONTAINER_TYPE_SEARCH: "UIConfig/query?field=filterName&value=ContainerTypeMaster",
    TEMPLATE_MASTER_SEARCH: "UIConfig/query?field=filterName&value=TemplateMaster",
    TEST_MASTER_SEARCH: "UIConfig/query?field=filterName&value=TestMaster",
    SUPPLIER_MASTER_SEARCH: "UIConfig/query?field=filterName&value=SupplierMaster",
    STORE_MASTER_SEARCH: "UIConfig/query?field=filterName&value=StoreMaster",
    STORE_ITEM_MAPPER_SEARCH: "UIConfig/query?field=filterName&value=StoreItemMapper",
    Mortuary: "Masters/UIConfig/query?field=filterName&value=Mortuary",
    BED_MASTER_SEARCH: "Masters/UIConfig/query?field=filterName&value=BedMaster",
    ITEM_TEMPLATE_MASTER_SEARCH: "Masters/UIConfig/query?field=filterName&value=ItemTemplateMaster",
    NOTIFICATION_SEARCH: "UIConfig/query?field=filterName&value=Notification",
    BED_BLOCK_LIST:"UIConfig/query?field=filterName&value=BedBlock",
    STAFF_MASTER_LIST:"UIConfig/query?field=filterName&value=StaffMasterConfig",
    DOCTOR_MASTER_LIST:"UIConfig/query?field=filterName&value=DoctorMasterConfig",
    GLOBAL_TARIFF_MASTER_SEARCH:"UIConfig/query?field=filterName&value=GlobalTariffConfig",
    LOCAL_TARIFF_MASTER_SEARCH:"UIConfig/query?field=filterName&value=LocalTariffConfig",
    OPERATION_MASTER_SEARCH:"UIConfig/query?field=filterName&value=OperationMaster",
    LOCAL_ITEM_TARIFF_MASTER_SEARCH:"UIConfig/query?field=filterName&value=LocalItemTariffConfig",
    GET_PATIENT:"patientSearch/getPatient",
    GET_CURRENT_ORDERS:"doctorOrder/getDoctorOrders",
    UPDATE_ORDERS:"doctorOrder/saveDoctorOrderSample",
    GET_PATIENT_VISIT_DETAILS:"encounter/get?mrn=",
    GET_CLINIC_DETAILS:"/OPD/#/external-patient-case-sheet/consult?orderType=",
    WARD_MASTER_SEARCH: "Masters/UIConfig/query?field=filterName&value=WardMaster",
    AUDIT_TRAIL_LOG: "UIConfig/query?field=filterName&value=AuditTrailLog",
    ADMISSION_FORM:"Masters/UIConfig/query?field=filterName&value=AdmissionForm",
    NEW_BORN:"Masters/UIConfig/query?field=filterName&value=NewBorn",
}
