import { Component, Input, Output, ViewChild, EventEmitter, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EnvoirnmentService } from "../../services/envoirnment.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  encapsulation: ViewEncapsulation.Emulated, 
})
export class FooterComponent {
  footerDate = new Date();
  medcareVersion: String;

  private isRefreshing = false;
  @Input() showFooter: boolean = false; 
  @Input() totalDataLength: number = 0;
  @Input() pageSize: number = 0;
  @Input() pageIndex: number = 0;
  @Input() paginatorOptions: number[] = [];

  @Output() pageChange = new EventEmitter<PageEvent>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Output() refresh = new EventEmitter<void>();

  constructor(
    public translate: TranslateService,
    private env: EnvoirnmentService
  ) {
    this.medcareVersion = this.env.medcareVerison;
  }

  getNext(event: PageEvent): void {
    if (!this.isRefreshing) {
      this.pageChange.emit(event); 
    }
  }
  onRefreshClick(): void {
    this.isRefreshing = true; 
    this.paginator.pageIndex = this.pageIndex;
    this.paginator.pageSize = this.pageSize;
    this.paginator.firstPage();
    this.refresh.emit();
    setTimeout(() => (this.isRefreshing = false), 0);
  }
}
