import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskData'
})
export class MaskDataPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || value.length < 2) {
      return value; // Return as is if the value is null, undefined, or too short
    }
    const firstDigit = value.charAt(0);
    const lastDigit = value.charAt(value.length - 1);
    const masked = 'X'.repeat(value.length - 2); // Generate the Xs for masking
    return `${firstDigit}${masked}${lastDigit}`;
  }
}
