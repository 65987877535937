import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class AesEncryptionService {

  private getCipherBlockSize(): number {
    return 16; 
  }

  private generateIv(): CryptoJS.lib.WordArray {
    const blockSize = this.getCipherBlockSize();
    const iv = new Uint8Array(blockSize);

    return CryptoJS.lib.WordArray.create(iv);
  }

  // Encrypt a text
  encryptText(plainText: string, encryptionKey: string): string {
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    
    const iv = this.generateIv();

    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    const encryptedText = encrypted.ciphertext.toString(CryptoJS.enc.Base64).replace(/\ /g, '+');

    return encryptedText;
  }

  // Decrypt an encrypted text
  decrypt(encryptedText: string,encryptionKey: string): string {
    const ciphertext = encryptedText;
    const key = CryptoJS.enc.Utf8.parse(encryptionKey);
    const ivWordArray = this.generateIv();

    const decrypted = CryptoJS.AES.decrypt(ciphertext, key, {
      iv: ivWordArray,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}