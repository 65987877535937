export enum MasterMenus {
  OPD = "OPD",
  Billing = "Billing",
  Lab = "Lab"
}

export enum MasterSubMenus {
  Phlebotomy = "Phlebotomy",
  Registration = "Registration"
}

export enum MasterChildSubMenus {
  CentralReceiving = " Central Receiving"
}

export enum QRScanTypes {
  SEARCH = "SEARCH"
}
export enum DocumentUploadEnum{
  FINAL="final",
  ENCOUNTER_SCREEN="ENCLI",
  MRD_SCREEN="MRD",
  LAB_SCREEN="LAB",
  REG_SCREEN="REG",
  ADT_SCREEN="ADT",
  DIAGNOSIS_SCREEN="DIGCOD",
  GL_SCREEN="GL",
  FOLDER_MRN="MRN",
  FOLDER_VISIT="VISIT",
  PATIENT="PATIENT",
  LAB_OUTSOURCE_SCREEN="LABOUT",
  MRD_MEDICAL_BOARD_SCREEN="MRDBOARD",
  MORTUARY="MORT"
}

export enum aesEncrypt {
  EncryptionKey = "bAbHf961YGedwsboiSjTHHhfESckV6AH"
}