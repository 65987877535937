import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { groupBy } from "lodash";
import { Subject, takeUntil } from 'rxjs';
import { MICROSERVICES, MICROSERVICESURL } from '../../properties/qms-url-properties';
import { QUEUE_STATUS } from '../../properties/queue-status-properties';
import { GeneralUrlService } from '../../services/generalUrl.service';
import { qmsSocketService } from 'projects/medcare-core-ui/src/app/services/qms.socket.service';
import { DatePipe } from '@angular/common';
const seconds = 10000; //10 secs

@Component({
  selector: 'app-qms-display',
  templateUrl: './qms-display.component.html',
  styleUrls: ['./qms-display.component.scss'],
  providers: [GeneralUrlService, DatePipe]
})
export class QmsDisplayComponent implements OnInit, OnDestroy {

  _onDestroy: Subject<boolean> = new Subject<boolean>();
  counterWiseResponse: any = [];
  currentCounter: any;
  currentIndex: any;
  counterInterval: any;
  servingList: any = []
  counterIntervalTokenNumber: NodeJS.Timer;
  mainServingList: any;
  currentNowServingIndex: any;
  currentServingList: any = [];
  alertList = [];
  tokenNumber: string;
  audio = new Audio();
  formattedDate: string;
  formattedTime: string;
  date = new Date();

  constructor(
    public generalApiService: GeneralUrlService,
    public snackBar: MatSnackBar,
    private readonly socketService: qmsSocketService,
    private datePipe: DatePipe) 
    { 
      this.formattedDate = this.datePipe.transform(this.date, 'dd-MM-yyyy') || '';
      this.formattedTime = this.datePipe.transform(this.date, 'hh:mm a') || '';
    }

  ngOnInit(): void {
  //  this.audio.src = "../../assets/audio/ring-new.mp3";
  //  this.audio.load();
   this.socketQueueList();
   this.QueueList();
  }

socketQueueList()
{
  this.socketService
      ._qmsconnect()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        let pushNotification = JSON.parse(res.body);
        this.searchQueueList(pushNotification);
      });
}

  searchQueueList(res) {
    this.servingList=[];
    this. counterWiseResponse=[];
    let oldSet ;
    if(this.mainServingList&&this.mainServingList.length>0)
    {
       oldSet = new Set(this.mainServingList.map(obj => obj.tokenNo));
    }
      if (res.length>0) {
        let list = groupBy(res,'doctorName');
        for (let key in list) {
          if(key!='null')
          {
          let detailObj = {
            counterName: "Doctor Name : " +key,
            list: list[key]
          }
          this.counterWiseResponse.push(detailObj);
        }
        }
        let departmentlist = groupBy(res,'departmentName');
        for (let key in departmentlist) {
          if(key!='null')
          {
          let detailObjDepart = {
            counterName: "Department Name : " +key,
            list: departmentlist[key]
          }
          let res=detailObjDepart.list.filter(s=>s.doctorName==null);
          detailObjDepart.list=res;
          if(detailObjDepart.list.length>0)
          this.counterWiseResponse.push(detailObjDepart);
        }
        }

        this.mainServingList = res.filter(data => data.status == QUEUE_STATUS.SERVING)
        const firstElement = this.mainServingList.shift();
        this.currentServingList = firstElement ? [firstElement] : [];
        // if (this.mainServingList.length > 8) {
        //   this.servingList = this.splitArrayIntoMultiDimensionalArray(this.mainServingList)
        //   this.currentServingList = this.servingList[0]
        // } else {
        //   this.servingList.push(this.mainServingList);
        //   this.currentServingList = this.servingList[0];
        // }
        if(oldSet)
        {
        const newlyAddedObjects = this.mainServingList.filter(obj => !oldSet.has(obj.tokenNo));
        console.log(newlyAddedObjects);
        if(newlyAddedObjects.length>0)
        this.tokenNumber=newlyAddedObjects[0].tokenNo;
        }
        this.currentNowServingIndex = 0;
        this.currentCounter = this.counterWiseResponse[0];
        this.currentIndex = 0;
        // this.alertToken();
      //  clearInterval(this.counterInterval);
      //   this.counterInterval = setInterval(() => {
      //     this.setCounterTimer()
      //   }, 10000);
      //   clearInterval(this.counterIntervalTokenNumber);
      //   this.counterIntervalTokenNumber = setInterval(() => {
      //     this.setCounterTimerTokenNo()
      //   }, 10000);
      }
  }

  QueueList() {
    let obj = {
      "counterIdList": []
    }
    this.generalApiService
      .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.GET_COUNTER_BY_UNIT, obj)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        if (res.length>0) {
          let list = groupBy(res,'doctorName');
          for (let key in list) {
            if(key!='null')
            {
            let detailObj = {
              counterName: "Doctor Name : " +key,
              list: list[key]
            }
            this.counterWiseResponse.push(detailObj);
          }
          }
          let departmentlist = groupBy(res,'departmentName');
          for (let key in departmentlist) {
            if(key!='null')
            {
            let detailObjDepart = {
              counterName: "Department Name : " +key,
              list: departmentlist[key]
            }
            let res=detailObjDepart.list.filter(s=>s.doctorName==null);
            detailObjDepart.list=res;
            if(detailObjDepart.list.length>0)
            this.counterWiseResponse.push(detailObjDepart);
          }
          }
  
          this.mainServingList = res.filter(data => data.status == QUEUE_STATUS.SERVING)
          const firstElement = this.mainServingList.shift();
          this.currentServingList = firstElement ? [firstElement] : [];
          // if (this.mainServingList.length > 8) {
          //   this.servingList = this.splitArrayIntoMultiDimensionalArray(this.mainServingList)
          //   this.currentServingList = this.servingList[0]
          // } else {
          //   this.servingList.push(this.mainServingList);
          //   this.currentServingList = this.servingList[0];
          // }
          this.currentNowServingIndex = 0;
          this.currentCounter = this.counterWiseResponse[0];
          this.currentIndex = 0;
          // this.alertToken();
         clearInterval(this.counterInterval);
          this.counterInterval = setInterval(() => {
            this.QueueList();
            this.formattedDate = this.datePipe.transform(this.date, 'dd-MM-yyyy') || '';
            this.formattedTime = this.datePipe.transform(this.date, 'hh:mm a') || '';
          }, 30000);
        //   clearInterval(this.counterIntervalTokenNumber);
        //   this.counterIntervalTokenNumber = setInterval(() => {
        //     this.setCounterTimerTokenNo()
        //   }, 10000);
        }
      });
    this.servingList=[];
    this.counterWiseResponse=[];
  }

  // setCounterTimer() {
  //   document.getElementById('left-div').classList.add("animate-div")
  //   this.currentIndex = (this.currentIndex + 1 >= this.counterWiseResponse.length) ? this.currentIndex = 0 : this.currentIndex + 1
  //   this.currentCounter = this.counterWiseResponse[this.currentIndex]
  //   setTimeout(() => {
  //     document.getElementById('left-div').classList.remove("animate-div")
  //   }, 2000);
  // }

  // setCounterTimerTokenNo() {
  //   document.getElementById('serving-div').classList.add("zoom-in-out-box")
  //   this.currentNowServingIndex = (this.currentNowServingIndex + 1 >= this.servingList.length) ? this.currentNowServingIndex = 0 : this.currentNowServingIndex + 1
  //   this.currentServingList = this.servingList[this.currentNowServingIndex]
  //   setTimeout(() => {
  //     document.getElementById('serving-div').classList.remove("zoom-in-out-box")
  //   }, 2000);
  // }

  // alertToken() {
  //   let _this = this;
  //   let ringIndex = 1000;
  //   this.alertList=[];
  //   if(this.currentServingList.length==1)
  //   {
  //     this.tokenNumber=this.currentServingList[0].tokenNo;
  //     setTimeout(function() {
  //       _this.audio.play();
  //     }, ringIndex);
  //     this.alertList.push(this.currentServingList[0]);
  //     ringIndex = ringIndex + seconds;
  //   }

  //  else{
  //    this.currentServingList.forEach(element => {
  //     if(element.tokenNo==this.tokenNumber)
  //     {
  //       setTimeout(function() {
  //         _this.audio.play();
  //       }, ringIndex);
  //       this.alertList.push(element);
  //       ringIndex = ringIndex + seconds;
  //     }
      
  //   });
  // }

  //   setTimeout(
  //     function() {
  //       _this.tokenNumber = "";
  //     },
  //     this.currentServingList.length == 1
  //       ? seconds
  //       : seconds * this.currentServingList.length + seconds
  //   );
  // }


  // splitArrayIntoMultiDimensionalArray(arr) {
  //   let chunks = [];
  //   for (let i = 0; i < arr.length; i += 0) {
  //     chunks.push(arr.slice(i, 8));
  //     arr.splice(0, 8)
  //   }
  //   return chunks;
  // }

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();
    clearInterval(this.counterInterval);
    clearInterval(this.counterIntervalTokenNumber);
  }

}
