import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ConfirmDialogComponent } from "../../core/confirm-dialog/confirm-dialog.component";
import { AppBaseService } from "../../services/http.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { AuthService } from "./../../services/auth.service";
import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { ReplaySubject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { CurrencyConversionService } from "../../services/CurrencyConversion.service";
import { MICROSERVICES } from "../../constants/web-services";
import { EnvoirnmentService } from "../../services/envoirnment.service";

@Component({
  selector: "app-payment-dialog",
  templateUrl: "./payment-dialog.component.html",
  styleUrls: ["./payment-dialog.component.scss"],
  providers: [DatePipe]
})
export class PaymentDialogComponent implements OnInit, OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  receivedFrom: string;
  indentificationNumber: string;
  remark: string;
  transferToActive: string;
  unitCode: any;
  unitId: any;
  orgId: any;
  orgCode: any;

  displayedNewDepositColumns = ["company", "depositAmount", "pay"];
  displayedColumnsDeposit = [
    "check",
    "depositno",
    "depositdate",
    "against",
    "encountertype",
    "encounterno",
    "depositamt",
    "consumeamt",
    "refundamt",
    "balance",
    "collectedby",
    "consume"
  ];
  dataSourceDeposit = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "paymentmode",
    "currency",
    "amount",
    "number",
    "bank",
    "cardtype",
    "transactiontype",
    "date",
    "action"
  ];
  dataSourcePayment = new MatTableDataSource<any>();
  currencies: any;
  banks: any;
  cardTypes: any;
  transactionTypes: any;
  paymentModes: any;
  deposit: boolean;
  paymentMode: string = "";
  amount = 0;
  accountNumber: string = "";
  bank: string = "";
  cardType: string = "";
  transactionType: string = "";
  paymentDate: any = "";
  totalDueAmount: number;
  disabled: boolean = true;
  actualAmount: number = 0;

  //exchange currency
  exCurrency: any;
  baseCurrency: number;
  convertedAmount: any;
  currency: string = "";
  currencyCode: string;

  //deposit
  newDeposit: any;
  totalNewDeposit: number = 0;
  dataSourceNewDeposit = new MatTableDataSource<any>();

  //flags
  isCurrencyAdded: boolean;
  isDisabled: boolean = true;
  newDepositCheckFlag: boolean = false;

  paymentForm: FormGroup = this.builder.group({
    paymentMode: [this.paymentMode, Validators.required],
    currency: [this.currency, Validators.required],
    amount: [this.amount, Validators.required],
    accountNumber: [this.accountNumber],
    bank: [this.bank],
    cardType: [this.cardType],
    transactionType: [this.transactionType],
    paymentDate: [this.paymentDate, Validators.required]
  });

  footerForm: FormGroup = this.builder.group({
    receivedFrom: [""],
    indentificationNumber: [""],
    remark: [""],
    transferToActive: [""]
  });
  totalPayment: number = 0;
  depositList: any[];
  patientId: string;
  isStock:boolean=false;
  depositAmount: any;
  refundAmount: any;
  balanceAmount: any;
  consumeAmount: any;
  consumedDeposits: any = [];
  pagename: string;
  consumeTotal: any;
  totalPayAmount: number;
  totalNonConvertedAmount: number;
  encounterId: any;
  visitType: string = "OP";
  digitsAfterDecimal: number = 2;
  baseCurrencyCode: string = "";
  isRefund: boolean;

  constructor(
    public dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: PaymentDialogData,
    public dialog: MatDialog,
    private baseservice: AppBaseService,
    private builder: FormBuilder,
    public datepipe: DatePipe,
    public snackBar: MatSnackBar,
    public authService: AuthService,
    private httpService: HttpClient,
    private currencyConversion: CurrencyConversionService,
    private env: EnvoirnmentService) {
      this.baseCurrencyCode = this.env.baseCurrency;
      this.deposit = dialogData.deposit;
      this.amount = dialogData.amount;
      this.baseCurrency = dialogData.amount;
      this.totalDueAmount = dialogData.amount;
      this.patientId = dialogData.patientId;
      this.isStock=dialogData.isStockService;
      this.pagename = dialogData.pagename;
      this.isRefund = dialogData.isRefund;
      this.newDeposit = dialogData.newDeposit ? dialogData.newDeposit : "";
      if (this.newDeposit) {
        this.updateNewDeposit(this.newDeposit);
      }
      if (dialogData.visitType) this.visitType = dialogData.visitType;
      this.encounterId = dialogData.encounterId ? dialogData.encounterId : "";
      if (this.deposit) {
        this.updateDeposit(dialogData);
      }
  }

  updateNewDeposit(newDeposit: any) {
    let dataList = [];
    this.totalNewDeposit = newDeposit.amount;
    dataList.push(newDeposit);
    this.dataSourceNewDeposit = new MatTableDataSource<any>(dataList);
  }

  updateDeposit(dialogData: PaymentDialogData) {
    // liogic
    var visitType;
    if (dialogData.patientAndDeposit && dialogData.pagename != "companysettlement") {
      if (
        dialogData.patientAndDeposit.visitType &&
        dialogData.patientAndDeposit.encounter
      ) {
        if (dialogData.patientAndDeposit.visitType == "OP") {
          visitType = "encounterNumber";
        } else if (dialogData.patientAndDeposit.visitType == "IP") {
          visitType = "admissionNumber ";
        }
        let param = `deposit/payer/SELF/all?${visitType}=${dialogData.patientAndDeposit.encounter}&mrn=${dialogData.patientAndDeposit.mrn}`;
        this.getdeposit(param);
      }
    } else if (dialogData.companyAndDeposit) {
      if (dialogData.companyAndDeposit.companyCode) {
        let param: string = "";
        if (
          dialogData.pagename == "selfsettlement" &&
          dialogData.companyAndDeposit.companyCode.toLowerCase() == "self"
        ) {
          param = `deposit/payer/${dialogData.companyAndDeposit.companyCode}/all?mrn=${dialogData.companyAndDeposit.mrn}`;
          this.getdeposit(param);
        } else if (
          dialogData.pagename == "companysettlement" &&
          dialogData.companyAndDeposit.companyCode.toLowerCase() != "self"
        ) {
          param = `deposit/payer/${dialogData.companyAndDeposit.companyCode}/all`;
          this.getdeposit(param);
        }
      }
    }
  }

  roundOffDecimal(num: number): number {
    num = isNaN(num) ? 0 : num;
    if (num > 0) {
      let round = (Math.round(num * 100) / 100).toFixed(
        this.digitsAfterDecimal
      );
      return parseFloat(round);
    } else return 0;
  }

  round(num) {
    return this.roundOffDecimal(num);
  }

  getdeposit(param) {
    this.depositAmount = 0;
    this.refundAmount = 0;
    this.consumeAmount = 0;
    this.balanceAmount = 0;
    this.depositList = [];

    this.baseservice.setResourceURL(MICROSERVICES.BILLING_SERVICE);
    this.baseservice.getResource(param)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(res => {
      if (res) {
        var t = this.getDepositGeneric(res);
        this.depositAmount = t.depositAmount;
        this.refundAmount = t.refundAmount;
        this.balanceAmount = t.balanceAmount;
        this.depositList = t.depositList;
        this.dataSourceDeposit = new MatTableDataSource<any>(this.depositList);
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  genericReturn: {
    depositAmount: number;
    refundAmount: number;
    consumeAmount: number;
    balanceAmount: number;
    depositList: any[];
  };

  getDepositGeneric(res): any {
    var depositAmount = 0;
    var refundAmount = 0;
    var consumeAmount = 0;
    var balanceAmount = 0;
    var depositList: any[] = [];

    if (res.length > 0) {
      res.forEach(element => {
        depositList.push({
          id: element.id,
          depositno: element.depositNo,
          depositdate: element.date,
          patientName: element.patientName,
          against: element.against,
          encountertype: element.visitType,
          encounterno: element.againstAccountNo,
          paymentmode: element.paymentMode,
          depositamt: element.depositAmt,
          consumeamt: element.consumedDeposit,
          refundamt: element.refundAmount,
          balance: element.availableDeposit,
          collectedby: element.createdBy,
          payments: element.listDepositDetails,
          checked: false,
          consumedAmount: 0,
          data: element
        });
        depositAmount += element.depositAmt;
        refundAmount += element.refundAmount;
        consumeAmount += element.consumedDeposit;
        balanceAmount += element.availableDeposit;
      });

      return (this.genericReturn = {
        depositAmount: depositAmount,
        refundAmount: refundAmount,
        consumeAmount: consumeAmount,
        balanceAmount: balanceAmount,
        depositList: depositList
      });
    }
  }

  ngOnInit() {
    this.isCurrencyAdded = false;
    this.authService.getUnit().then((value: any) =>{
      this.unitId = value.unitId;
      this.orgId = value.orgId;
      this.unitCode = value.unitCode;
      this.orgCode = value.orgCode;
    });
    this.getPaymentMode();
    this.getCurreny();
    this.getBank();
    this.getCardType();
    this.getExchangecurrency();

    this.paymentForm
      .get("paymentMode")
      .valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => this.onPaymentModeChange(data));
    this.paymentDate = this.datepipe.transform(new Date(), "dd-MMM-yyyy");
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  getExchangecurrency() {
    this.httpService.get("./assets/data/currency_rate.json")
    .pipe(takeUntil(this.unsubscribe))
    .subscribe({
      next: (res) => {
        this.exCurrency = res;
      },
      error: (err: HttpErrorResponse) => {
      }
    });
  }

  onPaymentModeChange(value: any) {
    let paymentDate = this.paymentForm.get("paymentDate");
    paymentDate.disable();

    if (value && value != 1) {
      this.isDisabled = false;
      this.editFields(value);
    } else if (value) {
      this.isDisabled = true;
      this.editFields(value);
    }
  }

  editFields(value) {
    let accountNumber = this.paymentForm.get("accountNumber");
    let bank = this.paymentForm.get("bank");
    let cardType = this.paymentForm.get("cardType");
    let transactionType = this.paymentForm.get("transactionType");

    let paymentMode = this.paymentModes.find(i => i.id == value);
    if (paymentMode) {
      accountNumber.setValue("");
      if (paymentMode.isCardTypeReq && value != 1) {
        accountNumber.setValidators([Validators.required]);
        accountNumber.enable();
      } else {
        accountNumber.setValidators([]);
        accountNumber.disable();
      }

      bank.setValue("");
      if (paymentMode.isBankReq && value != 1) {
        bank.setValidators([Validators.required]);
        bank.enable();
      } else {
        bank.setValidators([]);
        bank.disable();
      }

      cardType.setValue("");
      if (paymentMode.isCardTypeReq && value != 1) {
        cardType.setValidators([Validators.required]);
        cardType.enable();
      } else {
        cardType.setValidators([]);
        cardType.disable();
      }

      transactionType.setValue("");
      if (paymentMode.isBankReq && value != 1) {
        transactionType.enable();
      } else {
        transactionType.setValidators([]);
        transactionType.disable();
      }
    }
    accountNumber.updateValueAndValidity();
    bank.updateValueAndValidity();
    cardType.updateValueAndValidity();
    transactionType.updateValueAndValidity();
  }

  removePaymentRow(element, index) {
    let amount = this.totalDueAmount + element.amount;
    if (amount < this.totalNewDeposit) {
      this.newDepositCheckFlag = true;
    } else {
      this.newDepositCheckFlag = false;
    }
    if (amount > 0) {
      this.amount = this.round(
        (this.totalDueAmount - this.totalPayment) /
          (this.convertedAmount ? +this.convertedAmount : 1)
      );
    }
    const payments = this.dataSourcePayment;
    payments.data.splice(index, 1);
    this.dataSourcePayment = new MatTableDataSource<any>(payments.data);
    this.calculateTotalPayment();
    if (this.dataSourcePayment.data.length <= 0) {
      this.isCurrencyAdded = false;
    }
  }

  getCurreny(): any {
    this.baseservice.setResourceURL(MICROSERVICES.STOCK_SERVICE);
    this.baseservice.getResource("Masters/CurrencyMaster")
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(res => {
      this.currencies = res;
      if (this.currencies) {
        let tempCurrency = this.currencies.find(
          c => c.code == this.baseCurrencyCode
        );
        if (tempCurrency) {
          this.currency = tempCurrency.id;
          this.currencyCode = tempCurrency.code;
        } else {
          this.currency = "1";
          this.currencyCode = "MYR";
        }
      }
    });
  }

  getBank(): any {
    this.baseservice.setResourceURL(MICROSERVICES.STOCK_SERVICE);
    this.baseservice.getResource("Masters/BankMaster")
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(res => {
      this.banks = res;
    });
  }

  getCardType(): any {
    this.baseservice.setResourceURL(MICROSERVICES.STOCK_SERVICE);
    this.baseservice.getResource("Masters/CardTypeMaster")
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(res => {
      this.cardTypes = res;
    });
  }

  getPaymentMode(): any {
    let apiUrl = "Masters/PaymentModeMaster";
    if(this.isRefund) {
      apiUrl = "Masters/PaymentModeMaster/query?field=isRefund&value=" + this.isRefund;
    }
    this.baseservice.setResourceURL(MICROSERVICES.STOCK_SERVICE);
    this.baseservice.getResource(apiUrl)
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(res => {
      this.paymentModes = res;
      if (res && res.length > 0) {
        let defaultPayment = res.find(i => i.desc.toLowerCase() == "cash");
        if (defaultPayment) {
          this.paymentMode = defaultPayment.id
        } else {
          this.paymentMode = res.length > 0? res[0].id: "";
        };
      }
    });
  }

  addPayment() {
    this.actualAmount = this.amount + this.actualAmount;
    //To check whether the amount is negative
    if (this.currencyCode != this.baseCurrencyCode) {
      let tempAmount1 = +this.convertedAmount * this.amount;
      if (tempAmount1 > this.totalDueAmount) {
        this.snackBar.open(
          "Error",
          "Total amount cannot exceed payable amount " + this.totalDueAmount,
          {
            duration: 3000
          }
        );
      } else {
        this.amount = this.round(tempAmount1);
      }
    }
    if (this.amount <= 0) {
      this.snackBar.open("Warning", "Amount should be greater than zero", {
        duration: 3000
      });
      return false;
    } else if (this.paymentForm.valid) {
      let tempamount = (this.totalDueAmount - this.totalPayment).toFixed(2);
      let subAmount = Number(tempamount) - Number(this.amount);
      if (subAmount < 0) {
        this.snackBar.open(
          "Warning",
          "Total amount cannot exceed payable amount " + this.totalDueAmount,
          {
            duration: 3000
          }
        );
        return;
      } else {
        if (this.validateTotal()) {
          this.isCurrencyAdded = true;
          let paymentMode = this.paymentForm.value.paymentMode;
          if (paymentMode) {
            paymentMode = this.paymentModes.find(function check(data) {
              return data.id == paymentMode;
            });
          } else {
            paymentMode = { id: "", desc: "" };
          }

          let currency = this.paymentForm.value.currency;
          if (currency) {
            currency = this.currencies.find(function check(data) {
              return data.id == currency;
            });
          } else {
            currency = { id: "", desc: "" };
          }

          let bank = this.paymentForm.value.bank;
          if (bank) {
            bank = this.banks.find(function check(data) {
              return data.id == bank;
            });
          } else {
            bank = { id: "", desc: "" };
          }

          let cardType = this.paymentForm.value.cardType;
          if (cardType) {
            cardType = this.cardTypes.find(function check(data) {
              return data.id == cardType;
            });
          } else {
            cardType = { id: "", desc: "" };
          }

          let transactionType = this.paymentForm.value.transactionType;
          if (transactionType) {
            transactionType = this.transactionTypes.find(function check(data) {
              return data.id == transactionType;
            });
          } else {
            transactionType = { id: "", desc: "" };
          }

          const data = this.dataSourcePayment.data;
          data.push({
            paymentmode: paymentMode,
            currency: currency,
            amount: this.paymentForm.value.amount,
            convertedAmount: this.amount,
            number: this.paymentForm.value.accountNumber,
            bank: bank,
            cardtype: cardType,
            transactiontype: transactionType,
            date: this.datepipe.transform(new Date(), "dd-MMM-yyyy"),
            action: null
          });
          this.dataSourcePayment = new MatTableDataSource<any>(data);
          this.calculateTotalPayment();
          let amount = this.totalDueAmount - this.totalPayment;
          if (amount < this.totalNewDeposit) {
            this.newDepositCheckFlag = true;
          } else {
            this.newDepositCheckFlag = false;
          }
          if (amount > 0) {
            this.amount = this.round(
              (this.totalDueAmount - this.totalPayment) /
                (this.convertedAmount ? +this.convertedAmount : 1)
            );
          } else {
            this.amount = 0;
          }
        }
      }
    }
  }

  validateTotal() {
    let total = this.calculateTotal(this.dataSourcePayment.data, "amount");
    total = +total + +this.amount;
    if (total > this.totalDueAmount) {
      this.snackBar.open(
        "Warning",
        "Total amount cannot exceed payable amount " + this.totalDueAmount,
        {
          duration: 3000
        }
      );
      return false;
    } else {
      return true;
    }
  }

  consumeDeposit() {
    let consumeTotal = 0;
    let validation = true;
    let data = this.dataSourcePayment.data;
    let currency = this.paymentForm.value.currency;
    if (currency) {
      currency = this.currencies.find(function check(data) {
        return data.id == "MALAYSIA";
      });
      if (!currency) currency = { id: "", desc: "" };
    } else {
      currency = { id: "", desc: "" };
    }

    this.consumedDeposits.forEach(element => {
      if (parseFloat(element.consumedAmount) > parseFloat(element.balance)) {
        validation = false;
      }
      data.push({
        paymentmode: { id: "0", desc: "Deposit" },
        currency: { id: "", desc: "MALAYSIA" },
        amount: element.consumedAmount,
        number: "",
        bank: "",
        cardtype: "",
        transactiontype: "",
        date: this.datepipe.transform(element.depositdate, "dd/MM/yyyy"),
        check: null
      });
      consumeTotal = consumeTotal + parseFloat(element.consumedAmount);
    });

    if (validation) {
      if (consumeTotal + this.totalPayment > this.totalDueAmount) {
        this.snackBar.open(
          "Warning",
          "Total amount cannot exceed payable amount " + this.totalDueAmount,
          {
            duration: 3000
          }
        );
      } else {
        this.dataSourcePayment = new MatTableDataSource<any>(data);
        this.totalPayAmount = this.calculateTotal(
          this.dataSourcePayment.data,
          "convertedAmount"
        );
        this.totalNonConvertedAmount = this.calculateTotal(
          this.dataSourcePayment.data,
          "amount"
        );
        this.totalPayment =
          this.totalPayAmount + parseFloat(this.consumeAmount);
      }
    } else {
      this.snackBar.open(
        "Warning",
        "Cannot consume more than available deposit amount",
        {
          duration: 3000
        }
      );
    }
  }

  consumeOnCheck(checked, index) {
    if (!checked) {
      let data = this.dataSourceDeposit.data;
      data[index].consumedAmount = 0;
      this.dataSourceDeposit = new MatTableDataSource<any>(data);
    }
    this.calculateConsume();
  }

  depositConsumptionValid = true;
  consumeOnKeyUp(index) {
    let totalConsume = this.depositList
      .filter(i => i.checked)
      .reduce((sum, item) => sum + parseFloat(item.consumedAmount), 0);
    if (totalConsume + this.totalPayAmount > this.totalDueAmount) {
      let data = this.dataSourceDeposit.data;
      data[index].consumedAmount = 0;
      this.dataSourceDeposit = new MatTableDataSource<any>(data);
    } 
    this.calculateConsume();
  }

  calculateConsume() {
    this.consumeAmount = this.depositList
      .filter(i => i.checked)
      .reduce((sum, item) => sum + parseFloat(item.consumedAmount), 0);
    this.calculateTotalPayment();
  }

  calculateTotal(list, variable) {
    let sum = 0;
    list.forEach(element => {
      sum = sum + parseFloat(element[variable]);
    });
    return sum;
  }

  validateAmount() {
    let validation = true;
    if (
      this.pagename == "deposit" &&
      this.totalDueAmount != this.totalPayment
    ) {
      this.snackBar.open(
        "Warning",
        "Payment amount be equal to payable amount",
        {
          duration: 3000
        }
      );
      validation = false;
    } else if (
      (this.pagename == "opbill" || this.pagename == "selfsettlement" || this.pagename == "companysettlement") &&
      this.totalDueAmount < this.totalPayment
    ) {
      this.snackBar.open(
        "Warning",
        "Payment amount cannot be greater than Billing amount",
        {
          duration: 3000
        }
      );
      validation = false;
    } else if (
      (this.pagename == "companysettlement" || this.pagename == "selfsettlement") &&
      !this.depositConsumptionValid
      ) {
      this.snackBar.open(
        "Warning",
        "Value cannot be greater than Deposit Balance",
        {
          duration: 3000
        }
      );
      validation = false;
    }

    return validation;
  }

  getSelectedDeposits() {
    let deposits = [];
    this.dataSourceDeposit.data.forEach(element => {
      if (element.checked) {
        deposits.push(element);
      }
    });

    return deposits;
  }

  openConfirmDialog() {
    if (this.footerForm.valid) {
      if (this.validateAmount()) {
        const dialogRefConfirm = this.dialog.open(ConfirmDialogComponent, {
          width: "350px",
          data: { confirm: "no" }
        });
        dialogRefConfirm.afterClosed()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(result => {
          if (result.confirm == "yes") {
            const data = {
              payments: this.dataSourcePayment.data,
              identificationNumber: this.indentificationNumber,
              receivedFrom: this.receivedFrom,
              remark: this.remark,
              deposits: this.getSelectedDeposits(),
              paidAmoumt: this.totalPayment
            };
            this.dialogRef.close(data);
          }
        });
      }
    }
  }

  convertCurrency() {
    let convertAmount: any;
    let tempAmount = [];
    let currencyCode: any;

    currencyCode = this.currencies.find(i => i.id == this.currency);

    if (this.baseCurrencyCode != currencyCode.code) {
      this.currencyCode = currencyCode ? currencyCode.code : "MYR";
      convertAmount = this.currencyConversion.getCurrencyRates(
        this.currency,
        this.baseCurrencyCode
      );
      if (convertAmount) {
        tempAmount = convertAmount.split(":");
      }
      this.convertedAmount = tempAmount[1] ? tempAmount[1] : 0;
    }
  }

  calculateTotalPayment() {
    let consumeAmount = isNaN(this.consumeAmount)
      ? 0
      : parseFloat(this.consumeAmount);
    this.totalPayAmount = this.calculateTotal(
      this.dataSourcePayment.data,
      "convertedAmount"
    );
    this.totalNonConvertedAmount = this.calculateTotal(
      this.dataSourcePayment.data,
      "amount"
    );

    this.totalPayment = this.totalPayAmount + consumeAmount;
  }

  //deposit related change
  elementChecked(element) {
    if (element.checked) {
      this.totalDueAmount = this.totalDueAmount + element.amount;
    } else {
      this.totalDueAmount = this.totalDueAmount - element.amount;
    }
    let amount = this.totalDueAmount - this.totalPayment;
    if (amount > 0) {
      this.amount = this.round(
        (this.totalDueAmount - this.totalPayment) /
          (this.convertedAmount ? +this.convertedAmount : 1)
      );
    }
  }

  onCurrencyChange(event) {
    let currencyCode = "";
    if (event.value == 1) {
      currencyCode = "MYR";
      this.amount = this.baseCurrency;
      this.totalDueAmount = this.baseCurrency;
    } else if (event.value == 2) {
      currencyCode = "HKD";
    } else if (event.value == 3) {
      currencyCode = "USD";
    }
    if (this.amount > 0 && currencyCode != "MYR") {
      let rateData = this.exCurrency.rates[currencyCode]
        ? this.exCurrency.rates[currencyCode]
        : 0;
      if (rateData > 0) {
        this.amount = this.baseCurrency * rateData;
        this.totalDueAmount = this.baseCurrency * rateData;
      }
    }
  }
}

export interface DialogData {
  deposit: boolean;
  payments: any;
  receivedFrom: string;
  identificationNumber: string;
  remark: string;
  amount: number;
  patientId: string;
  pagename: string;
  encounterId: string;
  visitType: string;
}

export interface Deposit {
  date: string;
  voucherno: string;
  deposittype: string;
  totalamt: string;
  consumeamt: string;
  balance: string;
}

export interface Payment {
  paymentmode: string;
  currency: string;
  amount: string;
  number: string;
  bank: string;
  cardtype: string;
  transactiontype: string;
  date: string;
  action: string;
}

export class ExtraValidators {
  static conditional(conditional, validator) {
    return function(control) {
      revalidateOnChanges(control);

      if (control && control._parent) {
        if (conditional(control._parent)) {
          return validator(control);
        }
      }
    };
  }
}

function revalidateOnChanges(control): void {
  if (control && control._parent && !control._revalidateOnChanges) {
    control._revalidateOnChanges = true;
    control._parent.valueChanges
      .distinctUntilChanged((a, b) => {
        // These will always be plain objects coming from the form, do a simple comparison
        if ((a && !b) || (!a && b)) {
          return false;
        } else if (a && b && Object.keys(a).length !== Object.keys(b).length) {
          return false;
        } else if (a && b) {
          for (let i in a) {
            if (a[i] !== b[i]) {
              return false;
            }
          }
        }
        return true;
      })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        control.updateValueAndValidity();
      });

    control.updateValueAndValidity();
  }
  return;
}

export class PaymentDialogData {
  deposit: boolean = false;
  amount: number = 0;
  patientId: string = "";
  pagename: string = "";
  newDeposit: string;
  pageNo: number = 0;
  visitType: string = "";
  encounterId: string = "";
  isStockService:boolean=false;
  patientAndDeposit: {
    visitType: string;
    encounter: string;
    mrn: string;
  };
  companyAndDeposit: {
    companyCode: string;
    mrn: string;
  };
  isRefund: boolean;
}
