import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { OPD_CONST } from '../../regproperties/opd-static-properties';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppBaseService } from 'medcare-core-ui';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-encounter-appointmnet',
  templateUrl: './encounter-appointmnet.component.html',
  styleUrls: ['./encounter-appointmnet.component.scss'],
  providers: [DatePipe],
})
export class EncounterAppointmnetComponent implements OnInit, OnDestroy {
  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  private subscriptionList = new Subscription();
  protected _onDestroy = new Subject<void>();
  displayedColumns: string[] = OPD_CONST.todayPatientAppointment;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  accountDetails: any[] = [];
  chargesDetails: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<EncounterAppointmnetComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private appBaseService: AppBaseService,
    private datepipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.getPayerDetails(this.data);
  }

  /**
   * closing dialog
   */
  onClick() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
    this._onDestroy.next();
    this._onDestroy.complete();
    this.subscriptionList.unsubscribe();
  }

  /**
   * 
   * @param rowData 
   */
  getPayerDetails(rowData) {
    this.data = rowData.payload ? rowData.payload.response :[];
    this.dataSource = new MatTableDataSource<any>(this.data);
  }

  onRecordSelect(data){
    this.dialogRef.close(data);
  }
    /**
   *
   *
   * @param {number} epoch
   * @return {*}  {string}
   * @memberof PatientRegistrationComponent
   */
    epochToDateConverter(epoch: number): string {
      try {
        const epochString = epoch.toString().replace(/\D/g, "");
        const length = epochString.length;
        if (length == 10) {
          var d = new Date(0);
          d.setUTCSeconds(epoch);
        } else {
          var d = new Date(0);
          d.setUTCMilliseconds(epoch);
        }
        const datePipe = new DatePipe("en-US");
        const formattedDate = datePipe.transform(
          d,
          "EEE, LLLL dd, yyyy , hh:mm a"
        );
        return formattedDate;
      } catch (ex) {
        return "";
      }
    }
    getStatusColor(status: string): { text: string; backgroundColor: string } {
      let style: { text: string; backgroundColor: string } = {
        text: "",
        backgroundColor: "",
      };
      switch (status) {
        case "booked":
          style.text = "booked";
          style.backgroundColor = "#ffd88e";
          break;
        case "Arrived":
          style.text = "Arrived";
          style.backgroundColor = "#bef0bb";
          break;
        case "Cancelled":
          style.text = "Cancelled";
          style.backgroundColor = "#ffcdcd";
          break;
            case "Rescheduled":
            style.text = "Rescheduled";
            style.backgroundColor = "#649d9d";
            break;
          case "No Show":
            style.text = "No Show";
            style.backgroundColor = "#fdbd92";
            break;
          case "Payment Received":
            style.text = "Payment Received";
            style.backgroundColor = "#5f5fcb";
            break;
        default:
          break;
      }
    
      return style;
    }
}
