import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppBaseService, MICROSERVICES } from 'medcare-core-ui';
import { ReplaySubject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-diagnostic-details',
  templateUrl: './diagnostic-details.component.html',
  styleUrls: ['./diagnostic-details.component.scss']
})
export class DiagnosticDetailsComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;

  private unsubscribe: ReplaySubject<boolean> = new ReplaySubject(1);
  displayedColumns: string[] = ['icd', 'problem',  'type', 'dateOfOnset'];
  dataSource = new MatTableDataSource();
  constructor(public dialogRef: MatDialogRef<DiagnosticDetailsComponent>, 
    public baseHttpService: AppBaseService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
  this.getData();
  }
  getData() {
    this.baseHttpService.setResourceURL(MICROSERVICES.ANCILLARY_SERVICE);
    this.baseHttpService
      .postResource(MICROSERVICES.DIAGNOSIS_CODING + this.data, {})
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.sort = this.sort;
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
