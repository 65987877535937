<div>
    <div class="modal-header modalHeaderBlack">
      <h6 class="modal-title">Diagnosis </h6>
      <span aria-label="Close" class="close" (click)="onNoClick()" data-automation-attribute="span-close-dialog">
        <mat-icon matTooltip="Close">clear</mat-icon>
      </span>
    </div>
    <b class="section-head main-label"> Diagnosis  Details</b>
    <div class="example-container mat-elevation-z8 main-div" data-automation-attribute="divSearchResult">
  
      <mat-table mat-table [dataSource]="dataSource" matSort style=" padding: 4px; height: 25vh !important;">
  
        <ng-container matColumnDef="icd">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> ICD </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element"> {{element?.icdCode}} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="problem">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Problem </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element"> {{element.problem}} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="displayName">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Display Name </mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="doctorName">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Doctor Name</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element"> {{element.addedBy }} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="type">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Type</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element"> {{element.type}} </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="dateOfInactive">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Inactive</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element"> {{element.addedDate | date : 'dd/MM/y'}} </mat-cell>
        </ng-container>
  
  
        <ng-container matColumnDef="dateOfOnset">
          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Onset</mat-header-cell>
          <mat-cell mat-cell *matCellDef="let element"> {{element.addedDate | date : 'dd/MM/y'}} </mat-cell>
        </ng-container>
  
        <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
  </div>
