
<div class="appointment-body" data-automation-attribute="div-appointment">
   
    <div class="body">
      <div class="filter-left">
        <table class="table-filter" data-automation-attribute="table-appointment">
          <tbody>
            <tr>
              <td style="width: 45%">
                <mat-form-field
                  class="form-custom-width mgt-10"
                  appearance="outline"
                >
                  <mat-label>
                    {{ "AppointmentPage.appointmenttype" | translate }}
                  </mat-label>
                  <mat-select
                    [formControl]="appointmentTypeCtrl"
                    [(ngModel)]="appointmentType"
                    [placeholder]="'AppointmentPage.appointmenttype' | translate"
                    (selectionChange)="appointmentTypeChange($event)"
                    data-automation-attribute="select-appointment-type"
                    #appointmentTypeSelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="appointmentTypeDFltrCtrl"
                        class="full-width"
                        ngDefaultControl
                        [placeholderLabel]="'RegistrationPage.search' | translate"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let option of filteredappointmentType | async"
                      [value]="option.code"
                    >
                      {{ option.desc }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  class="form-custom-width mgt-10"
                  appearance="outline"
                >
                  <mat-label>{{
                    "AppointmentPage.consultationtype" | translate
                  }}</mat-label>
                  <mat-select
                    [formControl]="teleCtrl"
                    [(ngModel)]="teleconsultaion"
                    (selectionChange)="consultationOnchange($event, false)"
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="teleFilterCtrl"
                        class="full-width"
                        ngDefaultControl
                        [placeholderLabel]="'RegistrationPage.search' | translate"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let option of filteredTelecom | async"
                      [value]="option.code"
                    >
                      {{ option.desc }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  class="form-custom-width mgt-10"
                  appearance="outline"
                >
                  <mat-label>
                    {{ "AppointmentPage.department" | translate }}
                  </mat-label>
                  <mat-select
                    [formControl]="deptCtrl"
                    [(ngModel)]="department"
                    [placeholder]="'AppointmentPage.doctordepartment' | translate"
                    (selectionChange)="departmentOnChange($event, false)"
                    data-automation-attribute="select-appointment-type"
                    #deptTypeSelect
                  >
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="deptFilterCtrl"
                        class="full-width"
                        ngDefaultControl
                        [placeholderLabel]="'RegistrationPage.search' | translate"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                      *ngFor="let option of filteredDepts | async"
                      [value]="option.code"
                    >
                      {{ option.desc }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-checkbox
                  [(ngModel)]="mulipleDoctor"
                  name="muliple"
                  [disabled]="multiDocCheckbox"
                  (click)="changeSingleToMultiple()"
                >
                  {{ "AppointmentPage.multipledoc" | translate }}
                </mat-checkbox>
              </td>
              <td
                style="text-align: center; width: 25%"
                *ngIf="apptType == 'appt'"
              ></td>
              <td style="text-align: right; width: 30%">
                <i class="material-icons icon-custom green md-18"
                  >fiber_manual_record</i
                >
                {{ "AppointmentPage.available" | translate }}
                <i class="material-icons icon-custom red md-18"
                  >fiber_manual_record</i
                >
                {{ "AppointmentPage.booked" | translate }}
                <i class="material-icons icon-custom grey md-18"
                  >fiber_manual_record</i
                >
                {{ "AppointmentPage.unavailable" | translate }}
                <i class="material-icons icon-custom blue md-18"
                  >fiber_manual_record</i
                >
                {{ "AppointmentPage.blockedHold" | translate }} &nbsp;&nbsp;
                <span  aria-label="Close" class="close modalHeaderRed" (click)="onNoClick()" data-automation-attribute="span-close-dialog">
                    <mat-icon matTooltip="{{ 'AddInternalNotes.close' | translate }}">clear</mat-icon>
                  </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div class="section-app">
        <div class="section-left">
          <div class="left-panel">
            <div class="panel-head">
             {{ doctorsCount }} {{ "AppointmentPage.doctors" | translate }}
            </div>
            <div class="panel-body">
              <label *ngIf="!doctorList || doctorList.length == 0">{{
                "AppointmentPage.selectfilter" | translate
              }}</label>
              <div class="div-doctor-list">
                <ul
                  *ngIf="doctorList && doctorList.length > 0"
                  class="doctor-list">
                  <li
                    *ngFor="let doctor of doctorList"
                    class="{{ activeDoctor.id == doctor.id ? 'active' : '' }}"
                  >
                      <!-- <img  class="imageCircle clip-circle"
                      [src]="doctor.imagePath"> -->
                      <img class="imageCircle clip-circle" data-automation-attribute="imgDefaultUser" 
                      [src]="doctor.imagePath ? doctor.imagePath :(doctor.gender == 'MALE' ? 'assets/images/doctor (3).png' : 'assets/images/doctor (8).png')" alt="Doctor Image">
                      <span class="ib custom-label">
                      <b>{{ doctor.desc }} </b>
                        <button  class="example-button-row"   (click)="doctorOnChange(doctor)" mat-button color="primary">{{ doctor.available }}</button>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="section-right">
          <div class="right-panel">
            <div class="col-lg-12 control-section">
              <div class="content-wrapper">
                <div class="schedule-overview">
                  <div class="overview-header">
                    <div class="overview-titlebar">
                      <div class="left-panel">
                        <div
                          class="schedule-overview-title"
                          style="border: 1px solid transparent"
                        >
                          <span style="font-size: 12px;font-weight: 500;">{{
                            "AppointmentPage.appointmentcalendar" | translate
                          }}</span>
                        </div>
                      </div>
                     
                      <div class="app-div-time">
                        {{ "AppointmentPage.appointmentTime" | translate }}: <b
                          >{{selectedencounterSlots.start | date:'shortTime'}} -{{selectedencounterSlots.end
                          | date:'shortTime'}}</b
                        >
                      </div>
                    
                    </div>
                  </div>
                  <div class="div-contatiner-dates overflowAuto">
                  <div class="row">
                    <div
                      [ngClass]="(slot.selected)?'date-next active':(slot.past)?'date-next-black':(slot.blocked)?'date-next-blue':'date-next activ'"
                      *ngFor="let slot of dayWiseSlots"
                      (click)="slectedSlot(slot)"
                      style="cursor: pointer"
                    >
                      <ng-container>
                        {{slot.start | date:'shortTime'}} -{{slot.end |
                        date:'shortTime'}}</ng-container
                      >
                    </div>
                  </div>
                 </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>

    </div>
    <div class="right section-grey">
        <button id="Save Encounter" (click)="saveAppointment()" class="btnCyan" mat-mini-fab>
            <mat-icon matTooltip="{{ 'payerInfo.save' | translate }}">sim_card</mat-icon>
          </button>
        &nbsp;
        <button
        (click)="confirmClearPatientAppointment()"
        class="btnOrange"
        mat-mini-fab> <mat-icon matTooltip="{{ 'payerInfo.reset' | translate }}">refresh</mat-icon></button>
        &nbsp;   &nbsp;
      </div>
  </div>