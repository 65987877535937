import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QUEUE_STEPS } from '../../properties/queue-status-properties';
import { AppointmentDetails, IDoctorList } from '../../Interface/Itoken-Generation';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppBaseService, AuthService, EnvoirnmentService, PatientService } from 'medcare-core-ui';
import { interval, Subject, Subscription, takeUntil } from 'rxjs';
import { GeneralUrlService } from '../../services/generalUrl.service';
import {
  MICROSERVICES,
  MICROSERVICESURL,
} from "../../properties/qms-url-properties";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';
import { BaseObject } from '../../Interface/Ibase';

@Component({
  selector: 'app-new-qms-token-generation',
  templateUrl: './new-qms-token-generation.component.html',
  styleUrls: ['./new-qms-token-generation.component.scss'],
  providers: [GeneralUrlService]
})
export class NewQmsTokenGenerationComponent implements OnInit, OnDestroy {
  @ViewChild(NgxScannerQrcodeComponent) scanner: NgxScannerQrcodeComponent;

  currentStep: string = QUEUE_STEPS.STEP1;
  languages = [
    { code: 'en', name: 'English' , icon:'language'},
    { code: 'my', name: 'Malay', icon:'language' },
    // Add more languages here
  ];

  selectedLanguage = 'en';  // default to English
  mrnNumber: string = '';
  appointmentDetails:AppointmentDetails[]=[];
  patientDetails: any=[];
  _onDestroy: Subject<boolean> = new Subject<boolean>();

  tokenNo:string='';
  // selectedService: BaseObject;
  // selectedDept: string = "";
  serviceList: BaseObject[] = [];
  deptList: any[] = [];
  // selectedCardIndex: number | null = null;
  // selectedDeptIndex: number | null = null;
  doctorList:IDoctorList[]=[]
  slotList:any[]=[];
  selectedSlots: { [doctorIndex: number]: string } = {}; // Object to store selected slots for each doctor
  unitCode: string='';
  unitName: string='';
  orgCode: string='';
  selectedDoctorIndex:number=-1;
  selectedSlotIndex: number=-1;
  selectedSlot:any;
  selectedDoctor: any;
  identificationTypeList: BaseObject[] = [];
  genderList:BaseObject[]=[];
  countryCodeList: BaseObject[]=[];
  filterCountryCodeList: BaseObject[] = [];
  filterIdentifictionTypeList: BaseObject[] = [];

  public identificationTypeCtrl: FormControl = new FormControl();
  public countryCodeCtrl: FormControl = new FormControl();
  
  appointmentForm: FormGroup;
  trnNo: string='';
  patientType:string='';
  mobileNoMin: number;
  mobileNoMax: number;
  primaryIdRegex: string;
  isShown: boolean = false;
  checkInFlag: boolean = false;
  walkInFlag: boolean = false;
  bookingDetail: any;
  bookings: any[];
  value: number = 0;
  loading: boolean = false;
  selectedDept: string = "";
  searchDept: string = "";
  searchDoctor: string = "";
  searchService: string = "";
  filteredServices: BaseObject[];
  filteredDepartments: any;
  filteredDoctors: IDoctorList[];
  isSlots: boolean;
  backgroundColors: string[] = ['#e3ffd6', '#fffbf0', '#f1fff3', '#d0f2f7', '#f0ebd2', '#edf2fb', '#e2e2e2', '#f1ffc4' , '#ffe5ec'];
  appointmentNo: string;
  private subscriptionList = new Subscription();
  public qrOutput: string = "";
  isScanQr: boolean = false;
  isMrn: boolean = false;
  isIc: boolean = false;
  icNumber: string = "";
  encounterOnCheckIn: boolean = false;
  defaultServiceCode: string = "";
  searchOptions = [
    { value: 'mrn', viewValue: 'MRN' },
    { value: 'ic', viewValue: 'IC/Passport' },
    { value: 'appointmentNo', viewValue: 'App. No' }
  ];
  isAppointment: boolean = false;
  appointmentNumber: string;
  selectedPatient: any;
  scanTokenFlag: boolean = false;
  tokenNumber: string= "";
  selectedTokenNo: string = "";
  
  constructor( 
    public dialog: MatDialog,
    public generalApiService: GeneralUrlService,
    public snackBar: MatSnackBar,
    public appBaseService: AppBaseService,
    public changeDetector: ChangeDetectorRef,
    public authService: AuthService,
    public env: EnvoirnmentService,
    public patientService:PatientService,
  ) { }

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();
    this.subscriptionList.unsubscribe();
    if(this.scanner) this.scanner.stop();
  }

  ngOnInit(): void {
    this.appointmentForm = new FormGroup({
      idType: new FormControl('', Validators.required),
      idNo: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      countryCode: new FormControl('', Validators.required),
      mobNo: new FormControl('', Validators.required),
    });
    this.getUserDetails();
    // this.getGreetingText();
    this.identificationTypeCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterIdentificationTypeMasterList();
    });
    this.countryCodeCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterCountryCodeMasterList();
    });
  }

  get needsScrolling(): boolean {
    const slotHeight = 8;
    const maxVisibleSlots = 58 / slotHeight;
    return this.slotList.length > maxVisibleSlots;
  }

  getSelectedLabel() {
    const selected = this.languages.find(option => option.code == this.selectedLanguage);
    return selected ? selected.name : '';
  }

  getSelectedIcon() {
    const selected = this.languages.find(option => option.code == this.selectedLanguage);
    return selected ? selected.icon : '';
  }

  nextStep(value){
    if(value == 'check-in'){
      this.checkInFlag = true;
      this.walkInFlag = false;
      this.currentStep = 'check-in';
      this.scanTokenFlag = false;
    }
    else if(value == 'walk-in'){
      this.currentStep = 'walk-in';
      this.walkInFlag = true;
      this.checkInFlag = false;
      this.scanTokenFlag = false;
    }
    else if(value == 'scan-token'){
      this.currentStep = 'scan-token';
      this.walkInFlag = false;
      this.checkInFlag = false;
      this.scanTokenFlag = true;
    }
  }

  backStep(){
    if(this.checkInFlag){
      this.currentStep = 'check-in';
    }
    else if(this.walkInFlag){
      this.currentStep = 'walk-in';
    }
    else if(this.scanTokenFlag){
      this.currentStep = 'scan-token';
    }
    this.clearAll();
  }

  getAppointments() {
    let payload;
      if(this.isAppointment){
        payload ={
          appointmentNo: this.appointmentNumber
        }
      }
      else{
      payload={
        // appointmentNo: this.appointmentIdValue,
        // mobileNo: this.patientDetails[0].mobileNo,
        identificationNo: this.patientDetails[0].identificationNo
      }
      }
      this.generalApiService
      .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.GET_APPOINTMENT,payload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res.length > 0) {
          this.bookings = res;
          if(this.isAppointment){
            this.getBookingDetail(res[0]);
            this.clearSearchValue();
          }
          else{
          this.currentStep = 'appointment-list';
          this.patientDetails = [];
          this.clearSearchValue();
          }
        }else{
          this.snackBar.open("Appointment Not found","Error",{duration:2000});
          this.isShown = false;
          this.clearSearchValue();
        }
      });
  }

  clearSearchValue(){
    this.mrnNumber = "";
    this.icNumber = "";
    this.appointmentNumber = "";
    this.isMrn = false;
    this.isIc = false;
    this.isAppointment = false;
    this.tokenNumber = "";
  } 

  getPatient(){
    this.trnNo = null;
    if(this.isMrn || this.isIc || this.isAppointment){
      if(!this.isAppointment){
      let payload;
      if(this.isMrn){
      payload={
        mrn: this.mrnNumber,
        offset: 0,
        limit: 10
      }
      }
      else if(this.isIc){
        payload={
          identificationNo : this.icNumber,
          offset: 0,
          limit: 10
        }
      }
      this.generalApiService
      .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.GET_PATIENT,payload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        if (res.patientResponseList.length>0) {
          res.patientResponseList.map((data)=>{
            let patientAge: any = this.patientService.calculateAge( data.dob );
            data.age = data.dob ? patientAge.years + " " + patientAge.months + " " + patientAge.days : "";
            if(!data.image){
              data.image = "../assets/images/" + this.getGenderValue(data.gender);
            }
          })
          this.patientDetails=res.patientResponseList;
          this.isShown = true;
          this.clearSearchValue();
        }else{
          this.snackBar.open("Patient not found","Error",{duration:2000});
          this.isShown = false;
          this.clearSearchValue();
        }
      });
    }
    else{
      this.getConfirmation();
      if(this.walkInFlag){
        this.clearSearchValue();
      }
    }
    }else{
      this.snackBar.open("Please enter MRN Number or IC Number or Appointment No to search Patient","Validation",{duration:2000});
    }
  }

  goBack(){
    this.isShown = false;
    this.selectedDoctor = null;
    this.selectedDept = "";
    this.selectedSlot = null;
    this.bookingDetail = null;
    this.doctorList = [];
    this.deptList = [];
    this.slotList = [];
    this.selectedSlotIndex = -1;
    this.selectedDoctorIndex = -1;
    if(this.checkInFlag){
      if(this.isScanQr){
        this.currentStep = 'check-in';
        this.isScanQr = false;
      }
      else{
        this.currentStep = 'manual-search';
      }
      this.clearAll();
    }
    else if(this.walkInFlag){
      this.currentStep = 'book-appointment';
    }
    else if(this.scanTokenFlag){
      this.currentStep = 'token-search';
    }
  }

  getBookingDetail(booking){
    let patientAge: any = this.patientService.calculateAge( booking.dob );
    booking.age = booking.dob ? patientAge.years + " " + patientAge.months + " " + patientAge.days : "";
    this.bookingDetail = booking;
    this.currentStep = 'booking-confirmation';
  }

  getDetails(){
    if(this.selectedDoctor && this.selectedSlot){
      if(this.patientDetails.length == 0 && this.trnNo){
        this.bookingDetail= {
          patientName : this.appointmentForm.value.name,
          identificationNo :this.appointmentForm.value.idNo,
          age : "",
          gender :this.appointmentForm.value.gender ? this.appointmentForm.value.gender.desc : "",
          mobileNo :this.appointmentForm.value.mobNo,
          doctor : this.selectedDoctor,
          department : this.deptList.filter((data)=> data.code == this.selectedDept)[0],
          appointmentDateTime : this.selectedSlot?.start
        }
      }
      else{
        this.bookingDetail= {
          patientName : this.patientDetails[0].patientName,
          identificationNo : this.patientDetails[0].identificationNo,
          age : this.patientDetails[0].age,
          gender : this.patientDetails[0].gender,
          mobileNo : this.patientDetails[0].mobileNo,
          doctor : this.selectedDoctor,
          department : this.deptList.filter((data)=> data.code == this.selectedDept)[0],
          appointmentDateTime : this.selectedSlot?.start
        }
      }
      this.currentStep = 'booking-confirmation';
    }
    else{
      this.snackBar.open("Please select Doctor and any one Slot to proceed","Validation",{duration:2000});
    }
  }



  getGenderValue(value: string): string {
    switch (value) {
      case "MALE":
        return "male-blue.png";
      case "FEMALE":
        return "female-pink.png";
      default:
        return "male-blue.png";
    }
  }

  getConfirmation(){
    if(this.checkInFlag){
      this.getAppointments();
      // this.currentStep = 'appointment-list';
    }
    else if(this.walkInFlag){
      this.currentStep = 'book-appointment';
    }
  }

  getUserDetails() {
    this.authService.getUnit().then((value: any) => {
    this.unitCode=value.unitCode;
    this.unitName = value.unitDescription;
    this.orgCode=value.orgCode;
    this.getOrgAppConfigList();
    });
  }
  getAppConfigList(){
    let mrdConfig;
    this.appBaseService.setResourceURL(MICROSERVICES.OPD_SERVICE);
      this.appBaseService
        .getResource(MICROSERVICESURL.APP_CONFIG+this.unitCode+
          "&orgCode="+this.orgCode+"&moduleName=Patient_Management&keyName=")
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          if (res) {
            mrdConfig = res.keys;
            mrdConfig.forEach((element) => {
              if (element.key == "Default Country Code"){
                if (element.valueList.length > 0) {
                  this.appointmentForm.controls['countryCode'].setValue(element.valueList[0].code);
                  let defaultCountryCode=this.countryCodeList.find((i) => i.code == element.valueList[0].code);
                  this.setPatternToMobileNumber(defaultCountryCode);
                  this.patientDetails = [];
                  this.checkInFlag = false;
                  this.walkInFlag = true;
                  this.currentStep = QUEUE_STEPS.NEW_PATIENT;
                }
              }
            });
          }
        })
  }

  getToken(patient){
    if(this.checkInFlag && this.trnNo){
      this.walkInFlag = true;
      this.checkInFlag = false;
    }
    if(this.checkInFlag){
      if(!this.encounterOnCheckIn){
        this.getQueueToken(patient);
      }
      else{
        this.selectedPatient= patient;
        this.currentStep = 'select-payment';
      }
  }
  else if(this.walkInFlag){
    this.createAppointment();
  }
  }
  
  getEncounterToken(){
    if(this.selectedPatient){
      let payload={
      appointmentNo:this.selectedPatient.appointmentNo?this.selectedPatient.appointmentNo:'',
      mrn:this.selectedPatient.mrn?this.selectedPatient.mrn:''
      }
      this.generalApiService
      .postTypeApi(MICROSERVICES.OPD_SERVICE, MICROSERVICESURL.CREATE_ENCOUNTER,payload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        if(res.tokenNo){
        this.tokenNo=res.tokenNo?res.tokenNo:'';
        this.currentStep = 'print-token';
        this.getLoading();
        }
      });
    }
  }


  getQueueToken(patient?){
    let Payload;
    if(patient){
      if(patient.trn == null){
      Payload ={
          mrn: patient.mrn ? patient.mrn : '',
          qmServiceCode: this.defaultServiceCode,
          qmLabelIdList: [],
          isCheckIn: this.checkInFlag,
          appointmentNo: patient.appointmentNo ? patient.appointmentNo : null
      }
    }else{
      Payload={
        trn: patient.trn ? patient.trn : '',
        qmServiceCode: this.defaultServiceCode,
        qmLabelIdList: [],
        isCheckIn: this.checkInFlag
      }
    }
    }
    else if(this.selectedPatient){
      if(this.selectedPatient.trn == null){
      Payload ={
          mrn: this.selectedPatient.mrn ? this.selectedPatient.mrn : '',
          qmServiceCode: this.defaultServiceCode,
          qmLabelIdList: [],
          isCheckIn: this.checkInFlag,
          appointmentNo: this.selectedPatient.appointmentNo
      }
    }else{
      Payload={
        trn: this.selectedPatient.trn ? this.selectedPatient.trn : '',
        qmServiceCode: this.defaultServiceCode,
        qmLabelIdList: [],
        isCheckIn: this.checkInFlag
    }
    }
    }
    this.generalApiService
      .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.ADD_VISITOR,Payload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        this.tokenNo = res.tokenNo;
        this.currentStep = 'print-token';
        this.getLoading();
      })
  }

  getWalkIntoken(service){
    let Payload;
    if(this.scanTokenFlag){
      
      Payload = {
        qmServiceCode: service?.code,
        tokenNo: this.selectedTokenNo
      }
      if(this.patientDetails[0].mrn != null){
       Payload.mrn = this.patientDetails[0].mrn;
      }
      if(this.patientDetails[0].trn != null){
       Payload.trn = this.patientDetails[0].trn;
      }
    }
    else{
    if(this.patientType=="REG"){
    Payload ={
        mrn: this.patientDetails[0].mrn,
        qmServiceCode: service?.code,
        qmLabelIdList: [],
        isCheckIn: this.checkInFlag
    }
  }else{
    Payload={
      trn: this.trnNo,
      qmServiceCode: service?.code,
      qmLabelIdList: [],
      isCheckIn: this.checkInFlag
  }
  }
  }
    this.generalApiService
    .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.ADD_VISITOR,Payload)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      this.tokenNo = res.tokenNo;
      this.currentStep = 'print-token';
      this.getLoading();
    })
  }

  getLoading() {
    this.loading = true;
    this.value = 0; 

    const subs$: Subscription = interval(50).subscribe(() => {
      this.value += 100/(5000/50);
      if (this.value >= 100 && this.currentStep !==QUEUE_STEPS.STEP1) {
        subs$.unsubscribe();
        this.loading = false;
        this.value = 0;
        this.isShown = false;
        this.tokenNo = "";
        this.selectedTokenNo = "";
        this.currentStep = QUEUE_STEPS.STEP1;
        this.clearAll();
      }
    });
}

  getSlot(doctor,index){
    this.selectedSlotIndex = -1;
    this.selectedDoctorIndex = index;
    this.selectedDoctor = doctor;
    const day = new Date();
    const startOfDay = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 1, 0, 0); // Set time to 12:01 AM
    const startTime = Math.floor(startOfDay.getTime() / 1000); 
    day.setHours(23, 59, 59, 999); 
    const endTime = Math.floor(day.getTime() / 1000); 
    this.slotList=[];
    this.generalApiService
    .getTypeApi(MICROSERVICES.OPD_SERVICE, MICROSERVICESURL.GET_SLOT+doctor.code+"&departmentCode="+
      this.selectedDept+"&startTime="+startTime+"&endTime="+endTime)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      if(res && res[0]){
        res[0].Slots.forEach(element => {
          let startTime = Math.floor((new Date()).getTime() / 1000); 
          if(element.businessStatus=="free" && element.startDateTimeEpoch >= startTime)
          this.slotList.push(element);
          this.isSlots = true;
        });
      }else{
        this.slotList=[];
        this.isSlots = false;
      }
      
    })
  }
  getServices(flag?){
    this.generalApiService
    .getTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.GET_SERVICES)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      if(res.length > 0){
        if(flag){
          this.getDepartments();
        }
        else{
          this.serviceList = res.filter((item)=> item.isConsultation == false);
          this.filteredServices = this.serviceList;
          this.currentStep = QUEUE_STEPS.SELECT_SERVICE;
        }
      }
      else{
        this.snackBar.open("Please select Service","Error",{duration:2000});
      }
    });
  }
  createTempPatient(){
    if(this.appointmentForm.valid){
    let formValue=this.appointmentForm.controls;
    let countryCodeObj=this.getObjByCode(this.countryCodeList,formValue.countryCode.value);
   let payload={
      idTypeList: formValue.idType.value?this.createObjectList(formValue.idType.value):[],
      idNumber: formValue.idNo?formValue.idNo.value:'',
      patientName: formValue.name.value?formValue.name.value:'',
      genderList:formValue.gender.value?this.createObjectList(formValue.gender.value): [ ],
      countryCode: {
          code: countryCodeObj.code,
          display: countryCodeObj.desc,
          system: "EN"
      },
      mobileNo: formValue.mobNo.value?formValue.mobNo.value:''
  }
  this.generalApiService
  .postTypeApi(MICROSERVICES.OPD_SERVICE, MICROSERVICESURL.CRATE_TEMP_PATIENT,payload)
  .pipe(takeUntil(this._onDestroy))
  .subscribe((res: any) => {
    if(res.trn){
      this.trnNo=res.trn;
      this.currentStep = 'book-appointment';
    }
  },error=>{
    this.appointmentForm.reset();
  });
  }else{
    this.snackBar.open("Please fill all the fields","Error",{duration:2000});
    this.appointmentForm.markAllAsTouched();
  }
  }

  getDepartments(){
    this.generalApiService
    .getTypeApi(MICROSERVICES.MASTER_SERVICE, MICROSERVICESURL.GET_DEPARTMENTS)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      if(res.length > 0){
        this.deptList = res;
        this.filteredDepartments = res;
        this.currentStep = QUEUE_STEPS.SELECT_DEPARTMENT;
      }
      else{
        this.snackBar.open("Please select Department","Error",{duration:2000});
      }
    });
  }

  getDoctorList(dept){
    this.selectedDept = dept? dept.code: "";
    let payload={
      limit: 0,
      unitCode: this.unitCode,
      departmentCode: dept?.code,
      isIncludeDoctorSlot: true,
      typeCodeList: [
          "DR"
      ]
  }
    this.generalApiService
    .postTypeApi(MICROSERVICES.MASTER_SERVICE, MICROSERVICESURL.GET_EMPLOYEE_LIST,payload)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      if(res.payload && res.payload.employeeResponseList){
        let list : IDoctorList[]=[];
        res.payload.employeeResponseList.forEach((element) => {
          list.push({
            id: element.id,
            code: element.code,
            desc: element.desc,
            gender:element.gender,
           imagePath:element.aws3FilePath ? element.aws3FilePath : (element.empImagePath ?this.env.apiUrl+element.empImagePath : ""),
            available:
              element.doctoreAvailable === "No slots available"
                ? element.doctoreAvailable
                : "Next Availablity is " + element.doctoreAvailable,
                l1:element.l1,
                l2:element.l2,
                l3:element.l3
          });
          this.doctorList=list;
          this.filteredDoctors = list;
        });       
    // go to select doctor step
    this.currentStep =QUEUE_STEPS.SELECT_DOCTOR;
      }else{
        this.snackBar.open("Doctor not preasent in the selected department.","Validation",{duration:2000});
      }
    })
  }
  
  selectSlot(slotIndex, slot) {
    this.selectedSlotIndex =slotIndex;
    this.selectedSlot=slot;
  }
  createAppointment(){
   if(this.selectedSlot && this.selectedDoctor){
    let specialityObj=this.getObjByCode(this.deptList,this.selectedDept);
    let Payload;
    if(this.patientType=="REG"){
  Payload={
      patientType: {
          code: "REG",
          display: "registered",
          system: "EN"
      },
      patient: {
          code: this.patientDetails[0].mrn,
          display: this.patientDetails[0].patientId,
          system: "ExtendedPatient"
      },
      specialityList:specialityObj?this.createObjectList(specialityObj):[],
      doctorList: this.selectedDoctor?this.createObjectList(this.selectedDoctor):[],
      startTimeEpoch:this.selectedSlot? this.patientService.dateToEpochConverter(this.selectedSlot.start):0,
      endtimeEpoch: this.selectedSlot?this.patientService.dateToEpochConverter(this.selectedSlot.end):0,
      serviceList: [
          {
              system: "EN",
              code: "1",
              display: "Doctor"
          },
          {
              system: "MY",
              code: "1",
              display: "Doktor"
          }
      ],
      serviceCategory: {
          code: "1",
          display: "Doctor",
          system: "EN"
      },
      slots: this.selectedSlot?[
        this.selectedSlot.id
      ]:[],
      appointmentType: {
          code: "1",
          display: "Doctor",
          system: "EN"
      },
      consultationType: null
      // {
      //     code:this.selectedService.code?this.selectedService.code:'',
      //     display: this.selectedService.desc?this.selectedService.desc:'',
      //     system: "EN"
      // }
  }
  }else{
  Payload={
    patientType: {
        code: "TEMP",
        display: "temporary",
        system: "EN"
    },
    patient: {
        code: "",
        display: "",
        system: ""
    },
    specialityList:specialityObj?this.createObjectList(specialityObj):[],
    doctorList: this.selectedDoctor?this.createObjectList(this.selectedDoctor):[],
    startTimeEpoch:this.selectedSlot? this.patientService.dateToEpochConverter(this.selectedSlot.start):0,
    endtimeEpoch: this.selectedSlot?this.patientService.dateToEpochConverter(this.selectedSlot.end):0,
    serviceList: [
        {
            system: "EN",
            code: "1",
            display: "Doctor"
        },
        {
            system: "MY",
            code: "1",
            display: "Doktor"
        }
    ],
    serviceCategory: {
        code: "1",
        display: "Doctor",
        system: "EN"
    },
    slots: this.selectedSlot?[
      this.selectedSlot.id
    ]:[],
    appointmentType: {
        code: "1",
        display: "Doctor",
        system: "EN"
    },
    consultationType: null
  //   {
  //     code:this.selectedService.code?this.selectedService.code:'',
  //     display: this.selectedService.desc?this.selectedService.desc:'',
  //     system: "EN"
  // }
  ,
    trn:this.trnNo
  }
  }
  this.generalApiService
  .postTypeApi(MICROSERVICES.OPD_SERVICE, MICROSERVICESURL.CREATE_APPOINTMENT,Payload)
  .pipe(takeUntil(this._onDestroy))
  .subscribe((res: any) => {
    let patient;
    if(res.extendedPatient != null){
       patient = {
        mrn : res.extendedPatient.identifier[0].value
      }
    }
    else if (res.temporaryPatient != null){
       patient = {
        trn : res.temporaryPatient.trn
      }
    }
      this.getQueueToken(patient);
      // this.tokenNo = res.appointment.tokenNo;
      // this.currentStep = 'print-token';
      // this.getLoading();
    
  })
   }else{
    this.snackBar.open("Please select doctor and booking slot.","Validation",{duration:2000});
   }
  }
  // clearSelectedAll(){
  //   this.patientDetails=[];
  //   this.selectedSlot=null;
  //   this.selectedDoctor=null;
  //   this.slotList=[];
  //   this.serviceList=[];
  //   this.doctorList=[];
  //   this.filteredServices= [];
  //   this.filteredDepartments = [];
  //   this.filteredDoctors = [];
  //   this.selectedDept='';
  //   this.selectedSlotIndex = -1;
  //   this.selectedDoctorIndex = -1;
  //   this.tokenNo='';
  //   this.trnNo='';
  //   this.patientType='';
  // }

  getNewPatientForm(){
    this.getIdTypeList();
    this.getGenderList();
    this.getCountyCodeList();
  }
  getIdTypeList(){
    this.generalApiService
    .getTypeApi(MICROSERVICES.MASTER_SERVICE, MICROSERVICESURL.M_IDENTIFICATION_TYPE)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      this.identificationTypeList= res.filter((i) => i.isPrimaryId);
      this.filterIdentifictionTypeList= this.identificationTypeList;
    })
  }
  filterIdentificationTypeMasterList(){
    const searchTerm = this.identificationTypeCtrl.value ? this.identificationTypeCtrl.value.toLowerCase() : '';
    
    this.filterIdentifictionTypeList = this.identificationTypeList.filter(type =>
      type.desc.toLowerCase().includes(searchTerm)
    );
  }
  getGenderList(){
    this.generalApiService
    .getTypeApi(MICROSERVICES.MASTER_SERVICE, MICROSERVICESURL.M_GENDER)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      this.genderList=res;
    })
  }
  getCountyCodeList(){
    this.generalApiService
    .getTypeApi(MICROSERVICES.MASTER_SERVICE, MICROSERVICESURL.M_COUNTRY_CODE)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      this.countryCodeList=res;
      this.filterCountryCodeList=res;
      this.getAppConfigList();
    })
  }
  filterCountryCodeMasterList() {
    const searchTerm = this.countryCodeCtrl.value ? this.countryCodeCtrl.value.toLowerCase() : '';
    
    this.filterCountryCodeList = this.countryCodeList.filter(type =>
      type.desc.toLowerCase().includes(searchTerm)
    );
  }
  // getBackToSearch(){
  //   if(this.patientType=="REG"){
  //     this.currentStep = QUEUE_STEPS.PATIENT_DETAILS;
  //   }else{
  //     this.currentStep = QUEUE_STEPS.NEW_PATIENT;
  //   }
  // }
  clearAll(){
    this.patientDetails=[];
    this.selectedSlot=null;
    this.selectedDoctor=null;
    this.slotList=[];
    this.serviceList=[];
    this.doctorList=[];
    this.filteredServices= [];
    this.filteredDepartments = [];
    this.filteredDoctors = [];
    this.selectedSlotIndex=-1;
    this.selectedDept='';
    this.tokenNo='';
    this.trnNo='';
    this.patientType='';
    this.isScanQr = false;
    this.appointmentForm.reset();
    this.isMrn = false;
    this.isIc = false;
    this.isShown = false;
    this.isAppointment = false;
    this.selectedPatient = null;
    this.selectedTokenNo = "";
  }

  public createObjectList(object) {
    let languageList = JSON.parse(localStorage.getItem("langList"));
    let list = [];
    for(let langKey in languageList) {
        for(let objKey in object) {
            if(langKey == objKey) {
                let listItem = {
                    system: languageList[langKey].toString(),
                    code: object.code,
                    display: object[objKey].toString()
                }
  
                list.push(listItem);
            }
        }
    }
    if(list.length==0){
        return list=null;
    }
    return list;
  }
  getObjByCode(list: any[], code: string) {
    let value;
    if (list && list.length > 0) {
      value = list.find((i) => i.code == code);
    }
    return value;
  }
  //  maskString(inputString: string): string {
  //   const visibleChars = 2;
  //   const inputLength = inputString.length;
  
  //   if (inputLength <= visibleChars * 2) {
  //     return inputString;
  //   }
  
  //   const maskedLength = inputLength - visibleChars * 2; 
  //   const maskedPart = 'X'.repeat(maskedLength); 
    
  //   return inputString.substring(0, visibleChars) + maskedPart + inputString.slice(-visibleChars);
  // }
  setPatternToMobileNumber(event){
    if(event?.value){
      event = this.countryCodeList.find((i) => i.code == event.value);
    }
    if (event.regex) {
      const regexPattern = event.regex.replace(/^\/|\/$/g, '');
      this.appointmentForm.controls.mobNo.setValidators([
        Validators.required,
        Validators.pattern(new RegExp(regexPattern))
      ]);
      const matches = event.regex.toString().match(/\{(\d+),(\d+)\}/);
      if (matches && matches.length > 1) {
        this.mobileNoMax = Number(matches[2]);
        this.mobileNoMin = Number(matches[1]);
      }else{
      this.appointmentForm.controls.mobNo.clearValidators();
      }
    } else {
      this.appointmentForm.controls.mobNo.clearValidators();
      this.appointmentForm.controls.mobNo.setValidators([
        Validators.required,
      ]);
    }
    this.appointmentForm.controls.mobNo.updateValueAndValidity();
  }
   /**
     *primaryIdentification onchange check multiple conditions
     * @param idTypeCode
     */
     primaryIdentificationOnChange(idTypeCode: any) {
      let form = this.appointmentForm.controls;
      if (idTypeCode) {
        let primaryIdCode = this.identificationTypeList.find(
          (s) => s == idTypeCode.value
        );
        if (primaryIdCode) {
          if (primaryIdCode.isMyKad == true) {
            this.primaryIdRegex = "^[0-9 -]+$";
          } else {
            this.primaryIdRegex = primaryIdCode.validation
              ? primaryIdCode.validation
              : "";
          }
        }
        this.primaryNumberOnKeyUp(form.idNo.value);
       
      }
    }
  
    /**
     * primaryNumber onchange check multiple conditions
     * @param value
     */
    primaryNumberOnKeyUp(value: string) {
      try {
        let form = this.appointmentForm.controls;
        let primaryIdCode = form.idType.value;
  
        value = value.replace(/\s/g, '');
        form.idNo.setValue(value);
        if (primaryIdCode) {
          if (primaryIdCode.isMyKad) {
          }
  
          let primaryIdentify = this.identificationTypeList.find(
            (i) => i.code == primaryIdCode.code
          );
          if (primaryIdentify) {
            if (primaryIdentify.isMyKad) {
              var r = /(\D+)/g,
                npa = "",
                nxx = "",
                last4 = "";
              value = value.replace(r, "");
              var c: string = value;
              npa = value.substr(0, 6);
              nxx = value.substr(6, 2);
              last4 = value.substr(8, 4);
              value = npa + "-" + nxx + "-" + last4;
              form.idNo.setValue(value ? value : "");
              this.primaryIdRegex = "^[0-9 -]+$";
            } else if (primaryIdentify.isMyKad == false) {
              this.primaryIdRegex = "[A-Za-z0-9]*";
              form.idNo.setValue(value ? value : "");
            }
  
            if (value.length >= 12 && primaryIdentify.isMyKad) {
              const array = Array.from(c);
              const gen_val = array[11];
  
              const lastDigit = Number(gen_val);
              let genderVal = lastDigit % 2 == 0 ? "FEMALE" : "MALE";
              let gender=this.genderList.find((i)=>i.code===genderVal);
              form.gender.setValue(gender)
  
            }
          }
        }
      } catch (ex) {}
    }

    onSearchDoctorChange(){
      this.filteredDoctors = this.doctorList.filter(doc =>
        doc.desc.toLowerCase().includes(this.searchDoctor.toLowerCase())
      );
    }

    onSearchDeptChange(){
      this.filteredDepartments = this.deptList.filter(dept =>
        dept.desc.toLowerCase().includes(this.searchDept.toLowerCase())
      );
    }

    onSearchServiceChange(){
      this.filteredServices = this.serviceList.filter(service =>
        service.desc.toLowerCase().includes(this.searchService.toLowerCase())
      );
    }

    openBarcodeScanDialog() {
      // this.scanner.start();
      this.isScanQr = true;
      this.currentStep = 'scan-qr';
    }
  
    /**
     *
     * @param BarCode
     */
    applyFilter(BarCode) {
      if(this.isScanQr){
        this.appointmentNo=BarCode;
        this.getAppointment();
      }
    }

    getAppointment(){
      let payload={
        appointmentNo: this.appointmentNo,
        // mobileNo: this.patientDetails[0].mobileNo,
        // identificationNo: this.patientDetails[0].identificationNo
      }
      this.generalApiService
      .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.GET_APPOINTMENT,payload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res.length > 0) {
          this.getBookingDetail(res[0]);
        }else{
          this.snackBar.open("Appointment Not found","Error",{duration:2000});
          this.isShown = false;
        }
      });
    }

    // scan qr

    onCameraStart(action) {
      if(!this.scanner) this.scanner = action;
      action.start();
    }
  
    onBarcodeScanned(action, $event): void {
      this.qrOutput = $event? $event[0].value : null;
      // this.onClose();
      if(this.scanner) this.scanner.stop();
      this.applyFilter(this.qrOutput);
    }

    goToFirstStep(){
      this.currentStep = 'Step 2';
      this.appointmentForm.reset();
    }

    getPatientName(name: string): string {
      if (name.length > 25) {
        return name.substring(0, 25) + '...';
      }
      return name;
    }

    getDoctorName(name: string): string {
      if (name.length > 30) {
        return name.substring(0, 30) + '...';
      }
      return name;
    }

    goToWalkIn(){
      this.clearAll();
      this.currentStep = 'walk-in';
    }

    goToCheckIn(){
      this.clearAll();
      if(this.checkInFlag){
        this.currentStep = 'check-in';
      }
      else if(this.scanTokenFlag){
        this.currentStep = 'scan-token';
      }
    }
    
    goToManualSearch(){
      this.clearAll();
      this.currentStep = 'manual-search';
    }

    goToStepTwo(){
        this.clearAll();
        this.currentStep = 'Step 2';
    }

    getOrgAppConfigList() {
      let appConfig: any;
        this.appBaseService.setResourceURL(MICROSERVICES.OPD_SERVICE);
        this.appBaseService
          .getResource(MICROSERVICESURL.APP_CONFIG+this.unitCode+
            "&orgCode="+this.orgCode+"&moduleName=Queue_Management&keyName=")
          .pipe(takeUntil(this._onDestroy))
          .subscribe((res) => {
            if (res) {
              appConfig = res.keys;
              appConfig.forEach((element) => {
                if (element.key == "Encounter On Checkin"){
                  if (element.valueList.length > 0) {
                    this.encounterOnCheckIn = element.valueList[0].code=="true"?true:false;
                  }
                }
                if (element.key == "Default Front Desk Service"){
                  if (element.valueList.length > 0) {
                    this.defaultServiceCode = element.valueList[0].code;
                  }
                }
              });
            }
          })
    }

    onOptionSelected(event){
      if(event.value == "mrn"){
        this.isMrn = true;
        this.isIc = false;
        this.isAppointment = false;
      }
      else if(event.value == "ic"){
        this.isMrn = false;
        this.isIc = true;
        this.isAppointment = false;
      }
      else if(event.value == "appointmentNo"){
        this.isMrn = false;
        this.isIc = false;
        this.isAppointment = true;
      }
    }

    getPatientByToken(){
      this.trnNo = null;
      if(this.tokenNumber){
        let payload={
          tokenNo: this.tokenNumber
        }
        this.generalApiService
        .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.GET_PATIENT_BY_TOKEN_NO,payload)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res: any) => {
          if (res) {
              let patientAge: any = this.patientService.calculateAge( res.dob );
              res.age = res.dob ? patientAge.years + " " + patientAge.months + " " + patientAge.days : "";
              res.tokenNo = this.tokenNumber;
              this.selectedTokenNo = this.tokenNumber;
              if(!res.image){
                res.image = "../assets/images/" + this.getGenderValue(res.gender);
              }
            this.patientDetails= [res];
            this.isShown = true;
            this.clearSearchValue();
          }else{
            this.snackBar.open("Patient not found","Error",{duration:2000});
            this.isShown = false;
            this.clearSearchValue();
          }
        });
   
      }else{
        this.snackBar.open("Please enter Token Number to search Patient","Validation",{duration:2000});
      }
    }
  
}
